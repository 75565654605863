import Vue from 'vue';
import { Component, Inject, Prop, Watch } from 'vue-property-decorator';
import { RegisterBusinessModel } from '../../../model/businessregister.model';
import { STATES_LIST } from '../../../components/shared/statesList';
import { PermissionsEnum } from '../../../constants/permissions'
//@ts-ignore
import { mask } from 'vue-the-mask';
import ErrorModal from '../../../components/errors/modal/errormodal.vue'
import BusinessService from '../../../api-services/business.service';

@Component
    ({
        props: {
            listErrors: Array,

        },
        directives: { mask },
        components: {ErrorModal}
    })

export default class RegisterComponent extends Vue {

    @Prop()
    registerBusinessData!: RegisterBusinessModel;

    @Prop()
    showCancelButton!: boolean;

    @Prop()
    feinRequired!: boolean;

    public statesList = STATES_LIST;
    public maskContactPhone: string = '';
    public maskFein: string = '';

    public registerBusiness: RegisterBusinessModel = {} as RegisterBusinessModel;
    public registerBusinessOriginal: RegisterBusinessModel = {} as RegisterBusinessModel;
    public businessService: BusinessService = new BusinessService();

    // Error Modal
    public errorMessage: string = "";
    public showErrorModal: boolean = false;
    public duplicateFeinModalShow: boolean = false;

    public isLoading: boolean = false;

    @Watch('registerBusinessData', { immediate: true, deep: true })
    onChangedregisterBusiness(val: RegisterBusinessModel) {
        if (val) {
            if (Object.keys(val).length !== 0) {
                this.registerBusiness = val;
                this.registerBusinessOriginal = Object.assign({}, val);
                this.maskContactPhone = val.contactphone;
                this.maskFein = val.fein;

                var match = val.contactphone.match(/^(\d{3})(\d{3})(\d{4})$/)
                if (match) {
                    this.maskContactPhone = val.contactphone = '(' + match[1] + ') ' + match[2] + '-' + match[3];
                }

                match = val.fein.match(/^(\d{2})(\d{7})/)
                if (match) {
                    this.maskFein = val.fein = match[1] + '-' + match[2];
                }
            }
        }
    }


    get isStaff(): boolean {
        return this.$store.getters['user/permissions'].indexOf(PermissionsEnum.StaffAdmin) > -1;
    }

    get listErrorsProp(): any {
        return   this.$props.listErrors;
    }

    mounted(): void {
    }

 
    submit(): void {
        this.$validator.validateAll().then((isValid: boolean) => {
            if (isValid) {
                this.registerBusiness.fein = this.maskFein.replace(/-/g, '');
                this.registerBusiness.contactphone = this.maskContactPhone.replace(/[-()\s]/g, '');
                
                if (this.isStaff && this.registerBusiness.fein && (!this.registerBusinessOriginal.fein || this.registerBusinessOriginal.fein.trim() !== this.registerBusiness.fein)) {

                    this.businessService.isDuplicateFein(this.registerBusiness.fein).then(resp => {
                        if (resp.data) {
                            this.duplicateFeinModalShow = true;
                        }
                        else {
                            this.emitBusinessSubmit();
                        }
                    });
                } else {
                    this.emitBusinessSubmit();
                }
            }
            else {
                this.showErrorModal = true;
                this.errorMessage = 'Please correct errors before submitting';
            }
        })
    }


    cancel(): void {
       this.$emit("cancel")
       
    }

    errorOk(): void {
        this.showErrorModal = false;
    }

    emitBusinessSubmit(): void {
        this.$emit("businessSubmit", this.registerBusiness);
    }
}