import axios, { AxiosPromise } from 'axios';
import Base from '../components/shared/base.service';
import { UserModel } from '../model/user.model'
import { SubmitInvitationCodeModel } from '../model/submit.invitation.code.model';

export default class BusinessUserService extends Base {
    public constructor() {
        super();
    }

    public getUsers(businessId: number | null): AxiosPromise {
        return this.axiosHttp.get('/api/businessuser/users', {
            params: {
                businessId: businessId
            }
        })
    }

    public deleteUser(businessId: number | null, userId: number): AxiosPromise {
        return this.axiosHttp.get('/api/businessuser/delete', {
            params: {
                businessId: businessId,
                userId: userId
            }
        })
    }

    public addUser(user: UserModel): AxiosPromise {
        return this.axiosHttp.post('/api/businessuser/add', user);
    }

    public editUser(user: UserModel): AxiosPromise {
        return this.axiosHttp.put('/api/businessuser/edit', user);
    }

    public addUserByInvitationCode(model: SubmitInvitationCodeModel): AxiosPromise {
        return this.axiosHttp.post('/api/businessuser/code', model);
    }

}