import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { BusinessDetailsModel } from '../../../model/details.model';
import { PaginationModel } from '../../../model/pagination.model';
import Pagination from '../../../components/pagination/pagination.vue';
import { RequestBillingModel, RequestBillingItemModel } from '../../../model/requestbilling.model';
import BillingService from '../../../api-services/billingservice';
import BusinessService from '../../../api-services/business.service';
import BackButton from '../../../components/backbutton/backbutton.vue';
import phoneFormatter from '../../../formatter/phoneFormatter';
import dateFormatter from '../../../formatter/dateFormatter';

@Component({
    components: {
        BackButton,
        Pagination,
    },
    filters: {
        phoneFormatter,
        dateFormatter
    }
})
export default class RequestBilling extends Vue {
    public businessId: number = 0;
    public requestBatchId: number = 0;
    public business: BusinessDetailsModel = {} as BusinessDetailsModel;
    public requestBilling: RequestBillingModel = {} as RequestBillingModel;
    public businessService: BusinessService = new BusinessService();
    public isBusy: boolean = false;
    public currentPage_billing = 1;
    public perPage = 10;
    public pageOptions = [10, 20, 50];

    billingService = new BillingService();

    public fields: any = [
        { key: 'requestedBy', label: 'Requestor' },
        { key: 'courtNumber', label: 'Court Number' },
        { key: 'requestedName', label: 'Request Name' },
        { key: 'reportedName', label: 'Reported Name' },
        { key: 'lastFourOfSSN', label: 'Last Four of SSN' },
        { key: 'accountNumber', label: 'Account Number' },
        { key: 'basicCharge', label: 'Basic Charge' },
        { key: 'employerAddresses', label: 'Employer Addresses' },
        { key: 'totalDue', label: 'Total Due' }
    ];

    get totalRows(): number {
        return this.requestBilling.items.length;
    }

    mounted(): void {
        this.isBusy = true;
        this.businessId = parseInt(this.$route.query.businessId as string);
        this.requestBatchId = parseInt(this.$route.query.requestBatchId as string);

        this.billingService.get(this.requestBatchId).then(resp => {
            this.isBusy = false;
            this.requestBilling = resp.data;
        }).catch(error => {
            this.$router.push({ name: 'error' });
        }).then(x => {
            this.isBusy = false;
        });

        this.businessService.get(this.businessId).then(resp => {
            this.business = resp.data;
        }).catch(error => {

        });

    }

    clickDetails(): void {
        var location = { name: 'requestdetails', query: { requestBatchId: this.requestBatchId } } as any

        if (this.businessId > 0) {
            location.query.businessId = this.businessId;
        } 

        this.$router.push(location);
    }

    refreshPagination(model: PaginationModel): void {
        this.perPage = model.perPage;
        this.currentPage_billing = model.currentPage;
    }

}
