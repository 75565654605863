import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { BusinessDetailsModel } from '../../../model/details.model';
import phoneFormatter from '../../../formatter/phoneFormatter';

@Component({
    props: {
        businessDetails: Object,
        displayBusinessName: Boolean
    },
    filters: {
        phoneFormatter
    }
})
export default class BusinessDetails extends Vue {
    
    get selectedBusiness(): BusinessDetailsModel {
        return this.$props.businessDetails;
    }

    get displayBusinessNameProp(): boolean {
        return this.$props.displayBusinessName;
    }
    
    mounted(): void {

    }
}
