import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { BusinessDetailsModel } from '../../../../model/details.model';
import { VerifyRequestItemModel } from '../../../../model/verify.request.item.model';
import { StaffVerificationModel } from '../../../../model/staff.verifcation.model';
import { WageItemModel } from '../../../../model/wage.item.model';
import BusinessDetailService from '../../../../api-services/business.service';
import VerifyService from '../../../../api-services/requestverification.service';
import NGTSWageService from '../../../../api-services/ngtswage.service';
import { mask } from 'vue-the-mask';
import { dateValidator } from '../../../../validation/dateValidator';
import { SubmitVerificationModel } from '../../../../model/submit.verification.model';
import { PaginationModel } from '../../../../model/pagination.model';
import { UnableToProcessItemModel } from '../../../../model/unabletoprocess.item.model';
import WageSelect from '../../../../components/page/wageselect/wageselect.vue';
import BusinessDetails from '../../../../components/page/businessdetails/businessdetails.vue';
import Pagination from '../../../../components/pagination/pagination.vue';
import { RequestBatchStatusEnum } from '../../../../constants/requestbatchstatus';

@Component({
    directives: { mask },
    components: {
        WageSelect,
        BusinessDetails,
        Pagination
    }
})
export default class RequestVerify extends Vue {

    public businessDetailService: BusinessDetailService = new BusinessDetailService();
    public ngtsWageService: NGTSWageService = new NGTSWageService();
    public verifyService: VerifyService = new VerifyService();

    public selectedBusiness: BusinessDetailsModel = {} as BusinessDetailsModel;
    public verificationModel: StaffVerificationModel = {
        verifyRequestListItemViewModel: { list: [], totalItems: 0 }
    } as StaffVerificationModel;

    public $bvModal: any;
    public errorMessage: string = "";
    public requestIdSelected: number = 0;
    public batchId: number = 0;
    public isRerequest: string | (string | null)[] = '';
    public wageDataSelected: WageItemModel[] = [] as WageItemModel[];
    public wagesFound: WageItemModel[] = [] as WageItemModel[];
    public alertToast: boolean = false;
    public submitModel = {} as SubmitVerificationModel;
    public customNote: string = "";
    public requestId: number = 0;
    public noWagesFound: string = "No wages found within the last 5 years";

    public columns: any[] = [
        { key: 'ssn', label: "Last Four of SSN", thStyle: { width: '3%' } },
        { key: 'requestedName', thStyle: { width: '9%' } },
        { key: 'lookUpWages', label: "", thStyle: { width: '1%' } },
        { key: 'reportedName', label: "Reported Name", thStyle: { width: '9%' } },
        { key: 'wage', thStyle: { width: '5%' } },
        { key: 'quarterYear', label: "Qtr/Year", thStyle: { width: '4%' } },
        { key: 'hours', thStyle: { width: '1%' } },
        { key: 'courtNumber', label: "Court Number", thStyle: { width: '5%' } },
        { key: 'accountCA', label: "Account Number", thStyle: { width: '5%' } },
        { key: 'unableToProcessType', label: "Unable to Process", thStyle: { width: '9%' } },
        { key: 'notes', label: "Comments", thStyle: { width: '11%' } },
        { key: 'valid', label: "Status", thStyle: { width: '8%' } },
        { key: 'limited', thStyle: { width: '1%' } },
        { key: 'expirationDate', thStyle: { width: '8%' } },
        { key: 'savingSpinner', label: "",  thStyle: { width:  '2%' } }
    ];

    public currentPage = 1;
    public perPage = 25;
    public pageOptions = [25, 50, 75];
    public tableBusy = false;
    public clickedSubmit = false;
    errors: any;

    mounted(): void {
        this.$validator.extend('dateValidator', dateValidator);
        this.batchId = parseInt(this.$route.query.requestBatchId as string);
        this.isRerequest = this.$route.query.isRerequest;

        this.businessDetailService.getByBatchId(this.batchId).then(x =>
            this.selectedBusiness = x.data
        );

        this.tableBusy = true;
        this.verifyService.getVerificationView(this.batchId, this.perPage, this.currentPage).then(response => {
            this.verificationModel = response.data;
        }).catch(error => {
            this.errorMessage = "Unable to retrieve verification data"
            this.alertToast = this.toastBox;
        }).finally(() => {
            this.tableBusy = false;
        });

        this.submitModel.requestBatchId = this.batchId;
    }

    get toastBox(): boolean {
        return this.errorMessage.length > 0;
    }

    get totalRows(): number {
        return this.verificationModel.verifyRequestListItemViewModel.totalItems as number;
    }

    get isCompleted(): boolean {
        return this.verificationModel.batchStatusId == RequestBatchStatusEnum.Completed;
    }

    get isVoided(): boolean {
        return this.verificationModel.batchStatusId == RequestBatchStatusEnum.Voided;
    }

    get disabled(): boolean {
        return this.isCompleted || this.isVoided;
    }

    loadVerificationList(): void {
        this.tableBusy = true;
        this.verifyService.getVerifyList(this.batchId, this.perPage, this.currentPage).then(response => {
            this.verificationModel.verifyRequestListItemViewModel = response.data;
        }).catch(error => {
            this.errorMessage = "Unable to retrieve verification list"
            this.alertToast = this.toastBox;
        }).finally(() => {
            this.tableBusy = false;
        });
    }

    showWageModal(requestId: number): void {
        this.tableBusy = true;
        this.ngtsWageService.getWageData(requestId).then(response => {
            this.wagesFound = response.data as WageItemModel[];

            if (this.wagesFound && this.wagesFound.length > 0) {
                var request = this.getRequest(requestId);

                if (request)
                    this.wageDataSelected = request.wageList;

                this.$bvModal.show(this.wageModalName());
                this.requestIdSelected = requestId;
            }
            else {
                this.errorMessage = "Wages are not available";
                this.alertToast = this.toastBox;
            }
        }).catch(error => {
            this.errorMessage = "Unable to retrieve wages"
            this.alertToast = this.toastBox;
        }).finally(() => {
            this.tableBusy = false;
        });
    }

    matchesView(breakpoint: string): boolean {
        let breakpoints = { xs: "1px", sm: "576px", md: "768px", lg: "992px", xl: "1200px" };
        return window.matchMedia(`(min-width: ${breakpoints[breakpoint]})`).matches;
    }

    showSavingSpinner(requestId: number): boolean {
        return requestId == this.requestId;
    }

    showCustomNotesModal(requestId: number) {
        var request = this.getRequest(requestId);

        if (request) {
            this.customNote = request.notes;
            this.$bvModal.show(this.notesModalId());
            this.requestIdSelected = requestId;
        }

    }
    
    setRequestValid(rec: VerifyRequestItemModel): void {
        rec.unableToProcessType = null;
        this.validateRecord(rec);
        this.saveRow(rec.requestId);
    }

    setRequestInvalid(rec: VerifyRequestItemModel, unableToProcessType: UnableToProcessItemModel): void {
        rec.unableToProcessType = unableToProcessType;
        this.invalidateRecord(rec);
        this.$bvModal.hide(this.invalidModalId(rec.requestId));
        this.saveRow(rec.requestId);
    }

    setUnableProcessComment(unableToProcessTypeId: number, rec: VerifyRequestItemModel) {
        var unableToProcess = this.verificationModel.requestInvalidOptions.find(x => x.unableToProcessTypeId == unableToProcessTypeId);
        if (unableToProcess) {
            rec.unableToProcessType = unableToProcess;
            this.errorMessage = unableToProcess.text;
        }
        this.invalidateRecord(rec);
        this.saveRow(rec.requestId);
        this.alertToast = this.toastBox;
    }

    getRequest(requestId: number): VerifyRequestItemModel | undefined {
        return this.verificationModel.verifyRequestListItemViewModel.list.find((x: VerifyRequestItemModel) => x.requestId == requestId);
    }


    wageModalSave(model: WageItemModel[]): void {
        var request = this.getRequest(this.requestIdSelected);

        if (request) {
            request.wageList = model;
            request.valid = (model.length > 0);
            request.unableToProcessType = null;
            
            if (model.length > 0) {
                request.quarter = model[0].qtr.toString();
                request.year = model[0].year;
            }

            this.$bvModal.hide(this.wageModalName());
            const currentIndex = this.verificationModel.verifyRequestListItemViewModel.list.indexOf(request);
            this.verificationModel.verifyRequestListItemViewModel.list.splice(currentIndex, 1, request); //remove/updates the array
            this.saveRow(request.requestId);
        }
    }

    wageModalCancel(): void {
        this.$bvModal.hide(this.wageModalName());
    }

    wageModalName(): string {
        return 'wage-modal';
    }

    invalidModalId(recId: number): string {
        return 'invalid-modal-' + recId;
    }

    notesModalId(): string {
        return 'notes-modal';
    }

    voidModalId(): string {
        return 'void-modal';
    }

    validBtnSpinnerId(recId: number): string {
        return 'valid-loading-spinner-' + recId;
    }

    hasUnableToProcessComment(rec: VerifyRequestItemModel, unableToProcessTypeId: number) {
        if (rec.unableToProcessType)
            return rec.unableToProcessType.unableToProcessTypeId == unableToProcessTypeId;
    }

    modalNotesSave(): void {
        var rec = this.getRequest(this.requestIdSelected);
        if (rec) {
            rec.notes = this.customNote;
            this.saveRow(rec.requestId);
        }
    }


    checkLimited(requestId: number) {
        var request = this.getRequest(requestId);

        if (!request.limited)
            this.verificationModel.isAllLimited = false;

        this.saveRow(requestId);
    }

    saveRow(requestId: number): void {
        this.requestId = requestId;
        var rec = this.getRequest(requestId);
        if (rec != undefined)
            this.verifyService.saveVerification(rec).then(response => {
            }).catch(error => {
                this.errorMessage = "Error saving row: " + error.response.data.map(x => x.item2);
                this.alertToast = this.toastBox;
            }).finally(() => {
                this.requestId = 0;
            });
    }

    submitForm(bvModalEvt): void {
        bvModalEvt.preventDefault();
        this.clickedSubmit = true;
        this.verifyService.submitVerifications(this.submitModel).then(response => {
            if (response.data.hasAccountToSendEmail) {
                this.$router.push({ name: 'internaldashboard' });
            } else {
                this.$router.push({ name: 'staffverifyconfirmation', query: { requestBatchId: this.batchId.toString() } })
            }
        }).catch(error => {
            this.errorMessage = error.response.data[0].item2;
            this.alertToast = this.toastBox;
            this.clickedSubmit = false;
        });

    }

    invalidateAll(): void {
        if (!this.disabled) {
            for (let request of this.verificationModel.verifyRequestListItemViewModel.list as VerifyRequestItemModel[]) {
                this.invalidateRecord(request);
            }
            this.tableBusy = true;
            this.verifyService.invalidateAll(this.batchId).then(response => {
                
                }).catch(error => {
                    this.errorMessage = "Error invalidating all requests";
                    this.alertToast = this.toastBox;
                }).finally(() => {
                    this.tableBusy = false;
                });
        }
    }

    validateAll(): void {
        if (!this.disabled) {
            for (let request of this.verificationModel.verifyRequestListItemViewModel.list as VerifyRequestItemModel[]) {
                request.unableToProcessType = null;
                this.validateRecord(request);
            }
            this.tableBusy = true;
            this.verifyService.validateAll(this.batchId).then(response => {

            }).catch(error => {
                this.errorMessage = "Error validating all requests";
                this.alertToast = this.toastBox;
            }).finally(() => {
                this.tableBusy = false;
            })
        }
    }

    updateAllLimited(): void {
        this.tableBusy = true;
        this.verifyService.updateAllLimited(this.batchId, this.verificationModel.isAllLimited).then(response => {
            for (let request of this.verificationModel.verifyRequestListItemViewModel.list as VerifyRequestItemModel[]) {
                request.limited = this.verificationModel.isAllLimited;
            }
        }).catch(error => {
            this.errorMessage = "Error updating limited";
            this.alertToast = this.toastBox;
        }).finally(() => {
            this.tableBusy = false;
        });
    }

    invalidateRecord(rec: VerifyRequestItemModel): void {
        rec.valid = false;
        if (rec.wageList.length == 0) {
            rec.notes = rec.notes.replace(this.noWagesFound, "").trim();
        }
    }

    validateRecord(rec: VerifyRequestItemModel) {
        rec.valid = true;
        if (rec.wageList.length == 0 && !rec.notes.includes(this.noWagesFound)) {
            rec.notes = `${this.noWagesFound} ${rec.notes}`;
        }
    }

    showReportedWageData(value: any): boolean {
        return value == null || value;
    }

    resetToast() {
        this.alertToast = false;
        this.errorMessage = ""
    }

    clickComplete(): void {
        this.verifyService.isBatchRowsCompleted(this.batchId).then(response => {
            var result = response.data as boolean;

            if (result) {
                this.$bvModal.show('comment-modal');
            } else {
                this.errorMessage = "Please verify all subpoenas..."
                this.alertToast = this.toastBox;
            }
        }).catch(error => {

        });
    }

    clickEdit(): void {
        this.verificationModel.batchStatusId = RequestBatchStatusEnum.Inprogress;
    }

    clickVoid(): void {
        this.$bvModal.show(this.voidModalId());
    }

    modalVoidSave(): void {
        this.verifyService.voidRequestBatch(this.batchId).then(response => {
            this.$router.push({ name: 'internaldashboard' });
        }).catch(error => {
            this.errorMessage = 'An error occurred while voiding request';
            this.alertToast = this.toastBox;
        });
    }

    hasExpirationDateError(id: number): boolean {
        return this.errors.has('expirationDate' + id);
    }

    dateInputChanged(requestId: number, expirationDate: string): void {
        if (dateValidator.validate(expirationDate, null)) {
            this.saveRow(requestId);
        }
    }

    refreshPagination(model: PaginationModel): void {
        this.perPage = model.perPage;
        this.currentPage = model.currentPage;
        this.loadVerificationList();
    }
}
