import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import UserListService from '../../../api-services/user.service';
import BusinessUserService from '../../../api-services/businessuser.service';
import { UserListModel } from '../../../model/user.list.model';
import { UserModel } from '../../../model/user.model'
import AddUser from '../add/add.vue';
import EditUser from '../edit/edit.vue';
import AccountMenu from '../../../components/accountmenu/accountmenu.vue';
import LoadingSpinner from '../../../components/loading/loading.vue';
import UserRoleService from '../../../components/shared/userrole.service';
import ErrorModal from '../../../components/errors/modal/errormodal.vue';

@Component({
    components: {
        AddUser,
        EditUser,
        AccountMenu,
        LoadingSpinner,
        ErrorModal
    }
})
export default class UserList extends Vue {
    userListService: UserListService = new UserListService();
    businessUserService: BusinessUserService = new BusinessUserService();
    userRoleService: UserRoleService = new UserRoleService();

    public userAddModalShow: boolean = false;
    public userEditModalShow: boolean = false;
    public userDeleteModalShow: boolean = false;
    public userCannotDeleteModalShow: boolean = false;
    public users: UserListModel[] = [] as UserListModel[];
    public user: UserModel = {} as UserModel;
    public isLoading: boolean = false;
    public clickSaved: boolean = false;

    // Error Modal
    public errorMessage: string = "";

    get showErrorModal(): boolean {
        return this.errorMessage.length > 0;
    }

    get hasOneUser(): boolean {
        return this.users.length == 1;
    }

    mounted(): void {
        this.isLoading = true;
        this.businessUserService.getUsers(this.getBusinessId()).then(response => {
            this.users = response.data.users;
        }).catch(error => {
            this.$router.push({ name: 'error' });
        }).finally(() => {
            this.isLoading = false;
        });
    }

    getBusinessId(): number | null {
        return this.$store.getters['internal/businessId'];
    }

    addUser(user: UserModel): void {
        user.businessId = this.getBusinessId();
        this.clickSaved = true;
        this.businessUserService.addUser(user).then(response => {
            if (response.data) {
                this.addUserToArray(user, response.data);
            }
            this.userAddModalShow = !this.userAddModalShow;
        }).catch(error => {
            this.errorMessage = error.response.data[0];
        }).finally(() => {
            this.clickSaved = false;
        });
    }

    addCancel(): void {
        this.userAddModalShow = !this.userAddModalShow;
    }

    addLoadDialog(): void {
        this.user = { firstname: null, lastname: null, email: null, roleId: null } as UserModel;
        this.userAddModalShow = !this.userAddModalShow
    }

    isOnlyAdmin(roleId: number): boolean {
        if (roleId == 6) {
            var roles = this.users.filter((x: any) => x.roleId === 6);
            if (roles.length > 1) {
                return false;
            }
        }
            else if (roleId == 7 || roleId == null) {
                return false;
            }
        return true;
    }

    deleteUser(): void {
        this.clickSaved = true;
        this.businessUserService.deleteUser(this.getBusinessId(), this.user.userId).then(response => {
            this.deleteUserFromArray(this.user.userId);
            this.userDeleteModalShow = !this.userDeleteModalShow;
        }).catch(error => {
            this.errorMessage = error.response.data[0];
        }).finally(() => {
            this.clickSaved = false;
        });
    }

    deleteLoadDialog(user: UserListModel): void {
        this.user = user;
        if (this.isOnlyAdmin(user.roleId)) {
            this.cannotDeleteLoadDialog();
        }
        else {
            this.userDeleteModalShow = !this.userDeleteModalShow;
        }
    }

    cannotDeleteLoadDialog(): void {
        this.userCannotDeleteModalShow = !this.userCannotDeleteModalShow;
    }
    
    editLoadDialog(userId: number): void {
        this.user = Object.assign({}, this.users.find(x => x.userId == userId));
        this.userEditModalShow = !this.userEditModalShow;
    }

    editCancel(): void {
        this.userEditModalShow = !this.userEditModalShow;
    }

    editUser(user: UserModel): void {
        user.businessId = this.getBusinessId();
        this.clickSaved = true;
        this.businessUserService.editUser(user).then(response => {
            if (response.data) {
                if (user.userId == response.data) {
                    // same userId
                    let userEdit = this.users.find((x: UserListModel) => x.userId == response.data);
                    userEdit.email = user.email;
                    userEdit.firstname = user.firstname;
                    userEdit.lastname = user.lastname;
                    userEdit.roleId = user.roleId
                    userEdit.role = this.userRoleService.getUserRoles().find(x => x.id == user.roleId).value
                }
                else {
                    // different userId
                    this.deleteUserFromArray(user.userId);
                    this.addUserToArray(user, response.data);
                }
            }
            this.userEditModalShow = !this.userEditModalShow;
        }).catch(error => {
            this.errorMessage = error.response.data[0];
        }).finally(() => {
            this.clickSaved = false;
        });
    }

    addUserToArray(user: UserModel, userId: number) {
        let newUser = {
            firstname: user.firstname,
            lastname: user.lastname,
            email: user.email,
            userId: userId,
            roleId: user.roleId,
            role: this.userRoleService.getUserRoles().find(x => x.id == user.roleId).value
        } as UserListModel;

        this.users.push(newUser);
    }

    deleteUserFromArray(userId: number) {
        let userToDelete = this.users.find((x: UserListModel) => x.userId == userId);
        this.users.splice(this.users.indexOf(userToDelete), 1);
    }

    errorOk(): void {
        this.errorMessage = '';
    }
}
