import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { RequestUploadError } from '../../../model/request.upload.error.model';
import { PaginationModel } from '../../../model/pagination.model';
import vueDropzone from 'vue2-dropzone';
import Pagination from '../../../components/pagination/pagination.vue';
import ErrorModal from '../../../components/errors/modal/errormodal.vue';

@Component({
    components: {
        vueDropzone,
        Pagination,
        ErrorModal
    }
})
export default class RerequestNew extends Vue {
    public subpoenaErrors: RequestUploadError[] = [] as RequestUploadError[]
    private dataUploadDropzone: any;
    public isLoading: boolean = false;
    public errorMessage = "";
    public totalRows = 1;
    public currentPage = 1;
    public perPage = 10;
    public pageOptions = [10, 20, 50];

    public dropzoneOptions: any = {
        url: '/api/rerequest/dataupload',
        thumbnailWidth: 150,
        maxFilesize: 500,
        autoProcessQueue: false,
        addRemoveLinks: true,
        acceptedFiles: '.csv',
        maxFiles: 1,
        dictDefaultMessage: "Drag and drop file or click here to attach file"
    }

    public columns: any[] = [
        { key: 'row' },
        { key: 'column' },
        { key: 'errorMessage' }
    ];

    get hasRowErrors(): boolean {
        return this.subpoenaErrors.length > 0;
    }

    mounted(): void {
        this.subpoenaErrors = this.$store.getters['rerequest/requestUploadErrors'];
        this.totalRows = this.subpoenaErrors.length;
        this.dataUploadDropzone = this.$refs.dataUploadDropzone;
    }

    popOut(thisUrl: string) {
        var thisUrl = "/request/datalayout";
        window.open(thisUrl, 'popUpWindow', 'height=800,width=1700,left=50,top=50,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes');
    }

    fieldErrorClass(errors: any, fieldName: string): string {
        if (!errors)
            return "";

        if (errors.find((x: any) => x.field == fieldName))
            return "text-danger font-weight-bold";

        return "";
    }

    submit(): void {
        if (this.dataUploadDropzone.getQueuedFiles().length == 0) {
            this.errorMessage = "Please choose a valid CSV file to upload."
        }
        else {
            this.dataUploadDropzone.processQueue();
        }
    }

    uploadSuccess(file: any, response: any): void {
        this.$router.push({ name: 'rerequestconfirmation', query: { requestBatchId: response } });
    }

    uploadError(file: any, message: any, xhr: any): void {
        this.subpoenaErrors = [];
        this.isLoading = false;

        if (xhr.status == 422) {
            if (message.errorMessage) {
                this.errorMessage = message.errorMessage;
            }
            else {
                this.subpoenaErrors = message.rowErrors;
                this.totalRows = this.subpoenaErrors.length;
            }
        }
        else {
            this.errorMessage = "A System error has occured."
        }

        this.dataUploadDropzone.removeAllFiles();
    }

    sendingFile(file: any, xhr: any, formData: any): void {
        this.isLoading = true;
        formData.append('businessId', this.$store.getters['internal/businessId']);
    }

    refreshPagination(model: PaginationModel): void {
        this.perPage = model.perPage;
        this.currentPage = model.currentPage;
    }

    maxFilesError(file: any): void {
        this.dataUploadDropzone.removeFile(file);
        this.errorMessage = "There is a maximum limit of one PDF file."
    }

    errorOk(): void {
        this.errorMessage = "";
    }

    get showErrorModal(): boolean {
        return this.errorMessage.length > 0;
    }
}
