import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { ActivitySearchCriteria, ActivityResults } from '../../../../model/activity.report.model';
import ReportService from '../../../../api-services/report.service';
import { mask } from 'vue-the-mask';
import Datepicker from 'vuejs-datepicker';
import Loading from 'vue-loading-overlay';
import ErrorModal from '../../../../components/errors/modal/errormodal.vue';

@Component({
    filters: {
        commaSeparated(value) {
            if (!value)
                return '';

            return value.toLocaleString();
        }
    },
    directives: { mask },
    components: {
        Datepicker,
        Loading,
        ErrorModal
    },
})
export default class StatisticComponent extends Vue {
    
    public searchCriteria: ActivitySearchCriteria = {
        beginDate: null,
        endDate: null
    } as ActivitySearchCriteria;

    public isLoading: boolean = false;
    public result: ActivityResults = {} as ActivityResults;
    public reportService: ReportService = new ReportService();
    public errorMessage: string = "";

    mounted(): void {
        
    }

    get showErrorModal(): boolean {
        return this.errorMessage.length > 0;
    }

    search(): void {
        var self = this;
        this.isLoading = true;
        this.reportService.getActivity(this.searchCriteria)
            .then(function (response) {
                self.result = response.data as ActivityResults;
            })
            .catch(function (error) {
                this.errorMessage = 'There was an error with the search';
            })
            .then(function (response) {
                self.isLoading = false;
            });
    }

    clear(): void {
        this.searchCriteria.beginDate = null;
        this.searchCriteria.endDate = null;
    }

    errorOk(): void {
        this.errorMessage = "";
    }
}
