import axios, { AxiosPromise } from 'axios';
import Base from '../components/shared/base.service';

export default class RegisterService extends Base {
    public constructor() {
        super();
    }

    public submit(data: any): AxiosPromise {
        return this.axiosHttp.post('/api/register', data)
           
    }

}