import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import axios from 'axios';
import { InternalRequestSearchCriteria } from '../../../../model/internal.request.search.criteria.model';
import { InternalRequestSearchItemModel } from '../../../../model/internal.request.search.item.model';
import ErrorModal from '../../../../components/errors/modal/errormodal.vue';
import Pagination from '../../../../components/pagination/pagination.vue';
import { PaginationModel } from '../../../../model/pagination.model';
import { RequestBatchStatusEnum } from '../../../../constants/requestbatchstatus';
import feinFormatter from '../../../../formatter/feinFormatter'
import dateFormatter from '../../../../formatter/dateFormatter';
import { mask } from 'vue-the-mask';
import Datepicker from 'vuejs-datepicker';

@Component({
    name: "InternalRequestSearch",
    directives: { mask },
    components: {
        Datepicker,
        Pagination,
        ErrorModal
    }
})
export default class InternalRequestSearch extends Vue {

    public currentPage = 1;
    public perPage = 10;
    public pageOptions = [10, 20, 50];

    public searchCriteria: InternalRequestSearchCriteria = {
        businessName: '',
        requestBatchId: '',
        beginDate: null,
        endDate: null
    } as InternalRequestSearchCriteria;

    public isBusy: boolean = false;
    public errorMessage: string = "";
    public requestBatches: InternalRequestSearchItemModel[] = []

    public fields: any = [
        { key: 'requestBatchId', label: 'Id', sortable: true, tdClass: 'field' },
        { key: 'businessName', label: 'Business', sortable: true, tdClass: 'field' },
        { key: 'submitDate', label: 'Submitted', sortable: true, formatter: dateFormatter, tdClass: 'field' },
        { key: 'requestorName', label: 'Requestor Name', sortable: true, tdClass: 'field' },
        { key: 'requestorEmail', label: 'Requestor Email', sortable: true, tdClass: 'field' },
        { key: 'fein', label: 'FEIN', sortable: true, formatter: feinFormatter, tdClass: 'field' },
        { key: 'rerequest', label: 'Re-request', sortable: true, tdClass: 'field' },
        { key: 'status', label: 'Status', sortable: true, tdClass: 'field' },
        { key: 'statusDate', label: 'Status Date', sortable: true, formatter: dateFormatter, tdClass: 'field' },
        { key: 'processedByName', label: 'Processed By', sortable: true, tdClass: 'field' },
        { key: 'action', label: '', thStyle: "width: 200px" }
    ];

    get totalRows(): number {
        return this.requestBatches.length;
    }

    mount(): void {

    }

    requestComplete(statusId: number): boolean {
        return statusId == RequestBatchStatusEnum.Completed;
    }

    isSearchEmpty(): boolean {
        return this.searchCriteria.businessName.trim() === '' && this.searchCriteria.requestBatchId.trim() === ''
            && this.searchCriteria.beginDate == null && this.searchCriteria.endDate == null;
    }

    search(): void {
        if (this.isSearchEmpty()) {
            this.errorMessage = "One search field is required";
        }
        else {
            this.$validator.validateAll().then((isValid: boolean) => {
                if (isValid) {
                    this.isBusy = true;

                    axios.get('/api/internalrequestsearch', {
                        params: {
                            requestBatchId: this.searchCriteria.requestBatchId,
                            businessName: this.searchCriteria.businessName,
                            beginDate: this.searchCriteria.beginDate,
                            endDate: this.searchCriteria.endDate
                        }
                    }).then(response => {
                        this.requestBatches = response.data as InternalRequestSearchItemModel[];
                    }).catch((error: any) => {
                        this.errorMessage = "Sorry a system error has occured"
                    }).then(x => {
                        this.isBusy = false;
                    });
                }
            });
        }
    }

    get showErrorModal(): boolean {
        return this.errorMessage.length > 0;
    }

    errorOk(): void {
        this.errorMessage = '';
    }

    refreshPagination(model: PaginationModel): void {
        this.perPage = model.perPage;
        this.currentPage = model.currentPage;
    }

    clear(): void {
        this.searchCriteria.requestBatchId = '';
        this.searchCriteria.businessName = '';
        this.searchCriteria.beginDate = null;
        this.searchCriteria.endDate = null;
    }
}
