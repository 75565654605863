import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import BusinessUserService from '../../../api-services/businessuser.service'
import BusinessService from '../../../api-services/business.service'
import VueRecaptcha from 'vue-recaptcha';
import ErrorModal from '../../../components/errors/modal/errormodal.vue';
import SendInviteCodeModal from '../../../components/page/sendinvitecode/sendinvitecode.vue';
import { mask } from 'vue-the-mask';


@Component
    ({
        components: {
            ErrorModal,
            VueRecaptcha,
            SendInviteCodeModal
        },
        directives: { mask },
    })
export default class RegisterByCodeComponent extends Vue {
    businessUserService: BusinessUserService = new BusinessUserService();
    businessService: BusinessService = new BusinessService();

    public invitationCode: string = "";
    public fein: string = "";
    public feinRequired: boolean = false;

    public errorMessage: string = "";
    public isRecaptchaVerified: boolean | null = null;
    public recaptchaSiteKey: string = "6Lf3mcMUAAAAAOYRBAC9IqOp8mi_PG2T5ku6Egt8";
    public recaptchaResponse: string = "";
    public showSendInviteCodeModal: boolean = false;

    $router: any;

    mounted(): void {
    }

    submit(): void {

        var self = this;

        this.$validator.validateAll().then((isValid: boolean) => {
            if (isValid && this.isRecaptchaVerified) {
                this.businessService.hasFein(this.invitationCode).then(resp => {
                    self.feinRequired = !resp.data && resp.data !== "";
                    if (resp.data === "") {
                        self.errorMessage = "You have entered an invalid invite code"
                    }
                    else if (!resp.data && self.fein.trim().length === 0) {
                        self.errorMessage = "Please enter the business FEIN to continue"
                    }
                    else {
                        this.businessUserService.addUserByInvitationCode({ inviteCode: this.invitationCode, fein: this.fein.replace(/\D/g, ''), recaptchaResponse: this.recaptchaResponse })
                            .then(function (response) {
                                self.$store.dispatch('user/refreshUser').then(() => {
                                    self.$router.push({
                                        name: 'businessdetails'
                                    });
                                });
                            })
                            .catch(function (error) {
                                if (error.response.status == 422) {
                                    self.errorMessage = error.response.data[0];
                                }
                                self.resetRecaptcha(); 
                            });
                    }
                }).catch(x => {
                    self.errorMessage = "There was an error with the system";
                });


            }

            if (!this.isRecaptchaVerified)
                this.isRecaptchaVerified = false; 
        });
    }

    get showErrorModal(): boolean {
        return this.errorMessage.length > 0;
    }

    errorOk(): void {
        this.errorMessage = "";
    }

    onVerify(response: any) {
        if (response) {
            this.isRecaptchaVerified = true;
            this.recaptchaResponse = response;
        }
    }

    onExpired() {
        this.isRecaptchaVerified = false;
    }

    resetRecaptcha() {
        var recaptcha: any = this.$refs.recaptcha;
        recaptcha.reset();
        this.isRecaptchaVerified = null;
    }

    showContactEmailModal() {
        this.showSendInviteCodeModal = true;
    }
}
