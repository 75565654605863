import Vue from 'vue';
import Router from 'vue-router';
import { PermissionsEnum } from './constants/permissions';
import { store } from './store/store';

import RoleUpdate from './pages/internal/role/update.vue';
import LoginStaff from './pages/internal/login/login.vue';
import Login from './pages/login/login.vue';
import InternalDashboard from './pages/internal/dashboard/dashboard.vue';
import Dashboard from './pages/dashboard/dashboard.vue';
import Error from './pages/error/error.vue';
import Logout from './pages/logout/logout.vue';
import UserList from './pages/user/list/list.vue'
import NewRequest from './pages/request/new/new.vue';
import RequestDataLayout from './pages/request/datalayout/datalayout.vue';
import RequestBilling from './pages/request/billing/billing.vue';
import RequestDataEntry from './pages/request/dataentry/dataentry.vue';
import RequestDetails from './pages/request/details/details.vue';
import RequestVerify from './pages/internal/request/verify/verify.vue';
import StaffVerifyConfirmation from './pages/internal/request/confirmation/confirmation.vue';
import RequestUploadErrors from './pages/request/uploaderrors/uploaderrors.vue';
import RerequestNew from './pages/rerequest/new/new.vue';
import RerequestUploadErrors from './pages/rerequest/uploaderrors/uploaderrors.vue';
import UploadSubpoena from './pages/request/uploadsubpoena/uploadsubpoena.vue';
import RerequestSelect from './pages/rerequest/select/select.vue';
import RerequestConfirmation from './pages/rerequest/confirmation/confirmation.vue'
import RegisterByCode from './pages/business/registerbycode/registerbycode.vue';
import BusinessSearch from './pages/internal/business/search/search.vue';
import AccessDenied from './components/accessdenied/accessdenied.vue';
import BusinessDetails from './pages/business/details/details.vue';
import RequestConfirmation from './pages/request/confirmation/confirmation.vue';
import InternalBusinessRegister from './pages/internal/business/register/register.vue';
import RequestSearch from './pages/request/search/search.vue';
import InternalRequestSearch from './pages/internal/request/search/search.vue';
import ActivityReport from './pages/internal/report/activity/activity.vue';

Vue.use(Router);

const router = new Router({  
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/roleupdate',
            name: 'roelupdate',
            component: RoleUpdate,
            meta: {
                permission: [PermissionsEnum.AccountRequest, PermissionsEnum.StaffAdmin],
            },
        },
        {
            path: '/internal/login',
            name: 'loginstaff',
            component: LoginStaff,
            meta: {
                title: 'Staff Login'
            }
        },
        {
            path: '/internal/dashboard/',
            name: 'internaldashboard',
            component: InternalDashboard,
            meta: {
                permission: [PermissionsEnum.StaffAdmin],
                title: 'Internal Dashboard'
            },
        },
        {
            path: '/dashboard/',
            name: 'dashboard',
            component: Dashboard,
            meta: {
                permission: [PermissionsEnum.AccountRequest],
                title: 'Dashboard'
            },
        },
        {
            path: '/',
            name: 'home',
            component: Login,
            meta: {
                title: 'Home'
            }
        },

        {
            path: '/error',
            name: 'error',
            component: Error,
            meta: {
                title: 'Error'
            }
        },
        {
            path: '/logout',
            name: 'logout',
            component: Logout,
            meta: {
                title: 'Logout'
            }
        },
        {
            path: '/user/list/',
            name: 'userlist',
            component: UserList,
            meta: {
                permission: [PermissionsEnum.AccountRequest, PermissionsEnum.StaffAdmin],
                title: 'User List'
            },
        },
        {
            path: '/request/new/',
            name: 'newrequest',
            component: NewRequest,
            meta: {
                permission: [PermissionsEnum.AccountRequest, PermissionsEnum.StaffAdmin],
                title: 'New Request'
            },
        },
        {
            path: '/request/datalayout/',
            name: 'requestdatalayout',
            component: RequestDataLayout,
            meta: {
                permission: [PermissionsEnum.AccountRequest, PermissionsEnum.StaffAdmin],
                title: 'Request Data Layout'
            },
        },
        {
            path: '/request/billing/',
            name: 'requestbilling',
            component: RequestBilling,
            meta: {
                permission: [PermissionsEnum.AccountRequest, PermissionsEnum.StaffAdmin],
                title: 'Request Billing'
            },
        },
        {
            path: '/request/dataentry/',
            name: 'requestdataentry',
            component: RequestDataEntry,
            meta: {
                permission: [PermissionsEnum.AccountRequest, PermissionsEnum.StaffAdmin],
                title: 'Request Data Entry'
            },
        },
        {
            path: '/request/details/',
            name: 'requestdetails',
            component: RequestDetails,
            meta: {
                permission: [PermissionsEnum.AccountRequest, PermissionsEnum.StaffAdmin],
                title: 'Request Details'
            },
        },
        {
            path: '/internal/request/verify/',
            name: 'requestverify',
            component: RequestVerify,
            meta: {
                permission: [PermissionsEnum.StaffAdmin],
                title: 'Verify Request'
            },
        },
        {
            path: '/internal/request/confirmation/',
            name: 'staffverifyconfirmation',
            component: StaffVerifyConfirmation,
            meta: {
                permission: [PermissionsEnum.StaffAdmin],
                title: 'Verify Confirmation'
            },
        },
        {
            path: '/request/search/',
            name: 'requestsearch',
            component: RequestSearch,
            meta: {
                permission: [PermissionsEnum.AccountRequest],
                title: 'Request Search'
            },
        },
        {
            path: '/internal/request/search/',
            name: 'internalrequestsearch',
            component: InternalRequestSearch,
            meta: {
                permission: [PermissionsEnum.StaffAdmin],
                title: 'Request Search'
            },
        },
        {
            path: '/request/uploaderrors/',
            name: 'requestuploaderrors',
            component: RequestUploadErrors,
            meta: {
                permission: [PermissionsEnum.AccountRequest, PermissionsEnum.StaffAdmin],
                title: 'Request Upload Errors'
            },
        },
        {
            path: '/rerequest/uploaderrors/',
            name: 'rerequestuploaderrors',
            component: RerequestUploadErrors,
            meta: {
                permission: [PermissionsEnum.AccountRequest, PermissionsEnum.StaffAdmin],
                title: 'Rerequest Upload Errors'
            },
        },
        {
            path: '/rerequest/new',
            name: 'rerequestnew',
            component: RerequestNew,
            meta: {
                permission: [PermissionsEnum.AccountRequest, PermissionsEnum.StaffAdmin],
                title: 'New Rerequest'
            },
        },
        {
            path: '/request/uploadsubpoena',
            name: 'uploadsubpoena',
            component: UploadSubpoena,
            meta: {
                permission: [PermissionsEnum.AccountRequest, PermissionsEnum.StaffAdmin],
                title: 'Upload Subpoena'
            },
        },
        {
            path: '/rerequest/select',
            name: 'rerequestselect',
            component: RerequestSelect,
            meta: {
                permission: [PermissionsEnum.AccountRequest, PermissionsEnum.StaffAdmin],
                title: 'Select Rerequest'
            },
        },
        {
            path: '/rerequest/confirmation',
            name: 'rerequestconfirmation',
            component: RerequestConfirmation,
            meta: {
                permission: [PermissionsEnum.AccountRequest, PermissionsEnum.StaffAdmin],
                title: 'Rerequest Confirmation'
            },
        },
        {
            path: '/registerbycode',
            name: 'registerbycode',
            component: RegisterByCode,
            meta: {
                title: 'Register By Code'
            }
        },
        {
            path: '/internal/business/search',
            name: 'businesssearch',
            component: BusinessSearch,
            meta: {
                permission: [PermissionsEnum.StaffAdmin],
                title: 'Business Search'
            },
        },
        {
            path: '/internal/report/activity',
            name: 'activityreport',
            component: ActivityReport,
            meta: {
                permission: [PermissionsEnum.StaffAdmin],
                title: 'Activity Report'
            }
        },
        {
            path: '/accessdenied',
            component: AccessDenied,
            meta: {
                title: 'Access Denied'
            }
        },
        {
            name: 'businessdetails',
            path: '/business/details',
            component: BusinessDetails,
            meta: {
                permission: [PermissionsEnum.AccountRequest, PermissionsEnum.StaffAdmin],
                title: 'Business Details'
            },
        },
        {
            name: 'requestconfirmation',
            path: '/request/confirmation',
            component: RequestConfirmation,
            meta: {
                permission: [PermissionsEnum.AccountRequest, PermissionsEnum.StaffAdmin],
                title: 'Request Confirmation'
            },
        },
        {
            name: 'internalregisterbusiness',
            path: '/internal/business/register',
            component: InternalBusinessRegister,
            meta: {
                permission: [PermissionsEnum.StaffAdmin],
                title: 'Staff Register Business'
            },
        },

    ],
});

router.beforeEach(async (to, from, next) => {
    if (to.meta.permission) {
        store.dispatch('user/loadUser').then(x => {
            RouteToPage(to, next);
        });
    }
    else {
        next();
    }
});

function RouteToPage(to: any, next: any) {
    var permissions = store.getters['user/permissions'];
    if (!permissions) {
        next('/');
    }
    else if (permissions.length > 0 && permissions.some((r: any) => to.meta.permission.indexOf(r) >= 0)) {
        next();
    }
    else {
        next('/accessdenied');
    }
}

export default router;