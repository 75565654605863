import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { RequestEntry } from '../../../model/requestentry.model';
import { dateValidator } from '../../../validation/dateValidator';
import { ValidationProvider } from 'vee-validate';
//@ts-ignore
import { mask } from 'vue-the-mask'
import { numberValidator } from '../../../validation/numberValidator'
import { userStoreName, newRequestStoreName, internal } from '../../../store/names'
import RequestService from '../../../api-services/request.service'
import ErrorModal from '../../../components/errors/modal/errormodal.vue';
import BusinessService from '../../../api-services/business.service';
import NGTSWageService from '../../../api-services/ngtswage.service';

@Component
    ({
        components: {
            ValidationProvider,
            ErrorModal
        },
        directives: { mask }
    })
export default class RequestNew extends Vue {
    public userIsStaff: boolean = false;
    public businessContactName: string = '';

    public isNumericKeyPress = numberValidator.IsNumericKeyPress;
    public subpoenaRecords: RequestEntry[] = [] as RequestEntry[];
    public duplicateSSNs: number[] = [] as number[];

    public errorMessage: string = "";
    public showErrorModal: boolean = false;

    private requestService: RequestService = new RequestService();
    public businessService: BusinessService = new BusinessService();
    public ngtsWageService: NGTSWageService = new NGTSWageService();
    public fillNameToast: boolean = false;
    public currentIndex: number = -1;
    public currentSSN: string = "";

    businessId: number = 0;

    get lastRecordId(): number {
        return this.subpoenaRecords.reduce((max, p) => p.rowId > max ? p.rowId : max, this.subpoenaRecords[0].rowId);
    }

    indexOfRequestEntry(rowId: number): number {
        return this.subpoenaRecords.map((x: RequestEntry) => { return x.rowId }).indexOf(rowId);
    }

    mounted(): void {
        this.userIsStaff = this.$store.getters[userStoreName.isStaff];
        this.businessId = this.userIsStaff ? this.$store.getters[internal.businessId] : this.$store.getters[userStoreName.businessId];
        
        this.businessService.get(this.businessId).then(x => {

            this.businessContactName = x.data.businessName;
            let previousRecords = this.$store.getters[newRequestStoreName.requestData];

            if (previousRecords) {
                this.subpoenaRecords = previousRecords;
            }
            else {
                this.subpoenaRecords.push({ rowId: 1, requestorName: this.businessContactName } as RequestEntry);
            }
        });

        this.$validator.extend('dateValidator', dateValidator)
    }

    get toastBox(): boolean {
        return this.errorMessage.length > 0;
    }
    resetToast() {
        this.fillNameToast = false;
        this.errorMessage = ""
    }

    matchesView(breakpoint: string): boolean {
        let breakpoints = { xs: "1px", sm: "576px", md: "768px", lg: "992px", xl: "1200px" };
        return window.matchMedia(`(min-width: ${breakpoints[breakpoint]})`).matches;
    }

    submit(): void {
        
        this.$validator.validateAll().then((isValid: boolean) => {
            var hasDuplicateSSN = this.checkDuplicateSSNs();
            if (isValid && !hasDuplicateSSN) {
                if (this.$store.getters[userStoreName.isStaff]) {

                    this.requestService.submit(this.subpoenaRecords, this.$store.getters[internal.businessId]).then(response => {
                        this.$router.push({ name: 'requestconfirmation', query: { requestBatchId: response.data } })
                    });

                } else {
                    this.$store.dispatch(newRequestStoreName.loadNewRequest, this.subpoenaRecords);
                    this.$router.push({ name: 'uploadsubpoena' })
                }
            }
            else {
                this.showErrorModal = true;
                this.errorMessage = 'Please correct errors before submitting';
            }
        });
    }

    checkDuplicateSSNs(): any {
        var hasDuplicates = false;
        this.duplicateSSNs = [];
        var map = {};

        for (var i = 0; i < this.subpoenaRecords.length; i++) {
            var ssn: string = this.subpoenaRecords[i].ssn;

            if (!map[ssn]) {
                map[ssn] = [i];
            }
            else {
                map[ssn].push(i);
            }
        }

        for (var ssn in map) {
            if (map[ssn].length > 1) {
                map[ssn].forEach((index: number) => {
                    this.duplicateSSNs.push(index);
                });
                hasDuplicates = true;
            }
        }
        return hasDuplicates;
    }

    hasDuplicateSSN(index: number): boolean {
        return this.duplicateSSNs.indexOf(index) !== -1;
    }

    firstRow(rowId: number): boolean {
        return rowId == 1;
    }

    saveSSN(ssn: string ) {
        this.currentSSN = ssn;
    }


    addRecordTab(rowId: number, lastColumn: boolean) {
        if (!lastColumn && !this.userIsStaff)
            return;

        this.addRecord(rowId);

    }

    disableNameField(index: number): boolean {
        return this.currentIndex == index;
    }

    fillFirstLast(ssn: string, index: number): void {
        
        if (this.userIsStaff && ssn.length == 11) {
            if (this.currentSSN != ssn) {
                this.currentIndex = index;
                var ssnStripDash = ssn.replace(/-/g, "");
                this.ngtsWageService.fillName(ssnStripDash).then(response => {
                    if (response.data != "") {
                        var modifiedRow = this.subpoenaRecords[index];
                        modifiedRow.firstname = response.data.firstName;
                        modifiedRow.lastname = response.data.lastName;
                        this.subpoenaRecords.splice(index, 1, modifiedRow);//remove/updates the array
                    } else { throw "" };

                }).catch(error => {
                    this.errorMessage = "System error! Unable to retrieve name."
                    this.fillNameToast = this.toastBox;
                }).finally(() => {
                    this.currentIndex = -1;
                });
            }
        }
    }

    addRecord(rowId: number): void {
      

        if ((rowId == this.lastRecordId || !rowId) && this.subpoenaRecords.length < 25)
            this.subpoenaRecords.push({
                rowId: this.lastRecordId + 1,
                requestorName: this.businessContactName
            } as RequestEntry);
    }

    removeRecord(rowId: number): void {
        this.subpoenaRecords.splice(this.indexOfRequestEntry(rowId), 1);
    }

    errorOk(): void {
        this.showErrorModal = false;
    }

    previous(): void {
        this.$store.dispatch(newRequestStoreName.loadNewRequest, this.subpoenaRecords);
        this.$router.push({
            name: 'newrequest'
        });
    }

}
