import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import UserRoleService from './../../../components/shared/userrole.service'
import { UserModel } from '../../../model/user.model'

@Component({
    props: {
        userData: Object,
        lastAdmin: Boolean,
        isSaving: Boolean
    }
})
export default class UserProfile extends Vue {

    public userRoleService: UserRoleService = new UserRoleService();
    public roles: any = this.userRoleService.getUserRoles();

    get isSavingProp(): boolean {
        return this.$props.isSaving;
    }

    get onlyAdmin() {
        return this.$props.lastAdmin;
    }
    
    get user(): UserModel {
        return this.$props.userData;
    }

    mounted(): void {

    }
    
    public save(): void {
        this.$validator.validateAll().then((isValid: boolean) => {
            if (isValid) {
                this.$emit('save', this.user);
            }
        });
    }

    public cancel(): void {
        this.$emit('cancel');
    }
}
