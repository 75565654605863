import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { userStoreName } from '../../store/names';

@Component
export default class SessionTimeoutComponent extends Vue {

    mounted(): void { }

    get isUserLoggedIn(): boolean {
        return this.$store.getters[userStoreName.businessId] || this.$store.getters[userStoreName.isStaff]
    }

    get sessionTimoutSeconds(): number {
        return 30 * 60;
    }

    onIdle() {
        if (this.isUserLoggedIn) {
            window.location.href = '/home/signout';
        }
    }
}