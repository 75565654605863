
import Vue from 'vue';
import Menu from '@/components/navmenu/navmenu.vue';
import SessionTimeout from '@/components/sessiontimeout/sessiontimeout.vue';
import { Component, Watch  } from 'vue-property-decorator';
import { configName } from './store/names'

@Component({
    components: { Menu, SessionTimeout },
})
export default class AppComponent extends Vue {

    public pagesToCache: string[] = ['InternalRequestSearch'];

    @Watch('$route')
    onChangeRoute(to, from) {
        document.title = to.meta.title + ' - CSPS';
    }

    mounted() {

        if (!this.$store.getters[configName.isConfigSet]) {
            this.$store.dispatch(configName.loadConfig);
        }
       
    }

}

