import Vue from 'vue';
import { Component, Inject } from 'vue-property-decorator';




@Component
export default class AccessDenied extends Vue {
   
    
}
