import { AxiosPromise } from "axios";
import Base from "../components/shared/base.service"

export default class AlertService extends Base {
    public constructor() {
        super();
    }

    public get(alertRoute: string): AxiosPromise {
        return this.axiosHttp.get(`api/alert/${alertRoute}`);
    }
}
