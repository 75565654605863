import axios, { AxiosPromise } from 'axios';
import Base from '../components/shared/base.service';

export default class BusinessService extends Base {
    public constructor() {
        super();
    }

    public get(businessId: number | null): AxiosPromise {
        return this.axiosHttp.get('/api/business/details', {
            params: {
                businessId: businessId
            }
        })
    }

    public getByBatchId(requestBatchId: number): AxiosPromise {
        return this.axiosHttp.get('/api/business/details/batchId', {
            params: {
                requestBatchId: requestBatchId
            }
        });
    }

    public isDuplicateFein(fein: string): AxiosPromise {
        return this.axiosHttp.get('/api/business/isDuplicateFEIN/' + fein);
    }

    public hasFein(invitecode: string): AxiosPromise {
        return this.axiosHttp.get('/api/business/hasfein/' + invitecode);
    }

    public save(data: any): AxiosPromise {
        return this.axiosHttp.post('/api/business/update', data);
    }

    public sendInviteCode(contactEmail: string, recaptchaResponse: string): AxiosPromise {
        return this.axiosHttp.post('/api/business/sendinvitecode', {
            contactEmail: contactEmail,
            recaptchaResponse: recaptchaResponse
        });
    }

    public deleteBusiness(businessId: number): AxiosPromise {
        return this.axiosHttp.delete('/api/business/deletebusiness/' + businessId);
    }
}