import Vue from 'vue';
import { Component, Inject, Prop, Watch } from 'vue-property-decorator';



@Component
export default class ErrorModal extends Vue {

    @Prop()
    public showError!: Boolean;

    @Prop()
    public errorMessage!: string;


    mounted(): void {
    }

    errorOk(): void {
        this.$emit("errorClosed")
    }

}