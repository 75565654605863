
export const numberValidator = {
    IsNumericKeyPress(event: any): boolean {
        var keycode;

        keycode = event.keyCode ? event.keyCode : event.which;

        if (!(event.shiftKey == false && (keycode == 46 || keycode == 8 ||
            keycode == 37 || keycode == 39 || (keycode >= 48 && keycode <= 57)))) {
            event.preventDefault();
            return false;
        }
        else {
            return true;
        }
    }

};

