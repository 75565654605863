import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { RequestItemModel } from "../../../model/rerequest.item.model";
import { SearchCriteriaModel } from '../../../model/rerequest.search.criteria.model';
import RerequestService from "../../../api-services/rerequest.service";
import moment from 'moment';
import { dateValidator } from '../../../validation/dateValidator';
import { mask } from 'vue-the-mask';
import Datepicker from 'vuejs-datepicker';
import { PaginationModel } from '../../../model/pagination.model';
import Loading from 'vue-loading-overlay';
import Pagination from '../../../components/pagination/pagination.vue';
import dateFormatter from '../../../formatter/dateFormatter';
import { RerequestSubmitModel } from '../../../model/rerequest.submit.model';
import { RerequestSubmitItemModel } from '../../../model/rerequest.submit.item.model';
import ErrorModal from '../../../components/errors/modal/errormodal.vue';

@Component({
    filters: {
        dateFormatter
    },
    directives: { mask },
    components: {
        Datepicker,
        Loading,
        Pagination,
        ErrorModal
    },
})
export default class RequestNew extends Vue {

    public selectService: RerequestService = new RerequestService();
    public searchCriteria: SearchCriteriaModel = {} as SearchCriteriaModel;
    public requests: RequestItemModel[] = [] as RequestItemModel[];
    public selectedRecords: RequestItemModel[] = [] as RequestItemModel[]; //"shopping cart"
    public searchAction: boolean = false;
    public isLoading: boolean = false;
    public errorMessage: string = "";
    public windowWidth = window.innerWidth;


    public fields: any[] = [
        { key: 'requestBatchId', label:'ID', sortable: false, thStyle: { width: '90px' }, sortOrder:1  },
        { key: 'requestDate', label: 'Request date', sortable: false, thStyle: { width: '100px' }, formatter: dateFormatter, sortOrder: 2 },
        { key: 'requestedFirstName', label: 'First name', sortable: false, sortOrder: 3},
        { key: 'requestedLastName', label: 'Last name', sortable: false, sortOrder: 4},
        { key: 'court', label: 'Court', sortable: false, sortOrder: 5},
        { key: 'accountCA', label: 'Account', sortable: false, sortOrder: 6},
        { key: 'ssn', label: 'Last 4 of SSN', sortable: false, sortOrder: 7},
        { key: 'expiration', label: 'Expiration', sortable: false, thStyle: { width: '150px' }, sortOrder: 8 },
        { key: 'comments', label: 'Comments', sortable: false, thStyle: { width: '150px' }, sortOrder: 9},
        { key: 'selection', label:'Selection', sortable: false, sortOrder: 10},
   
    ];

    get checkBoxColumns(): any {
        var filtered = this.fields
            .filter(x => x.label != '')
            .map(function (obj) {
                return { value: obj.key, text: obj.label };
            });
        return filtered;
    }

    get filteredFields(): any {
        let filtered = this.selected.map((obj) => this.fields.find(x => x.key == obj));

        return filtered.sort(function (a, b) {
            return a.sortOrder - b.sortOrder
        });
    }

    public selected = [ 'requestedFirstName', 'requestedLastName', 'court', 'accountCA', 'ssn', 'expiration', 'comments', 'selection'];

   
    public totalRows = 1;
    public currentPage = 1;
    public pageOptions = [25, 50, 75];
    public perPage = this.pageOptions[0];
    public isSubmitting: boolean = false;
    public bounce: boolean = false;

    
    errors: any;

    mounted(): void {
        this.$validator.extend('dateValidator', dateValidator);

    }

    disabledExpirationDates = {
        to: new Date() //greater than or equal to today
    }

    disabledDatesSearch = {
        from: new Date()
    }

    get selectedRecordsCount(): number {
        if (this.selectedRecords == null)
            return 0;

        return this.selectedRecords.length;
    }

    get displayNoResults(): boolean {
        return this.searchAction && this.requests && this.requests.length == 0;
    }

    get selectedCheck(): number[] {
        return this.selectedRecords.map(m => m.requestId);
    }

    get isChecked(): boolean {
        return this.selectedCheck.length > 0;

    }

    get showErrorModal(): boolean {
        return this.errorMessage.length > 0;
    }



    clear(): void {
        this.requests = [] as RequestItemModel[];
        this.selectedRecords = [] as RequestItemModel[];
    }

    dateSelectionChanged(requestId: number): void {
        var request = this.selectedRecords.find((x: RequestItemModel) => x.requestId == requestId);

        if (request != undefined && this.selectedRecords.indexOf(request) != -1) {
            this.selectedRecords.splice(this.selectedRecords.indexOf(request), 1);
        }
    }

    clearSearchFields(): void {
        this.searchCriteria = {} as SearchCriteriaModel;
    }

    search(reset: boolean): void {

        if (reset) {
            this.perPage = this.pageOptions[0];
            this.currentPage = 1;
        }

        this.searchCriteria.limit = this.perPage;
        this.searchCriteria.currentPage = this.currentPage;
        this.isLoading = true;

      
        this.searchCriteria.businessId = this.$store.getters['internal/businessId'];

        this.selectService.searchFilter(this.searchCriteria).then(response => {
            this.isLoading = false;
            this.requests = response.data.list as RequestItemModel[];
            this.totalRows = response.data.totalItems;
            this.searchAction = true;
            for (let request of this.requests) {
                request.expiration = moment(request.expiration).format('MM/DD/YYYY');
            }
            
        }).catch(error => {
            
        });
    }

    changeSelection(event: any, rec: RequestItemModel): void {
        
        if (event == null || !event) {
            let recordFound = this.selectedRecords.find((x: RequestItemModel) => x.requestId == rec.requestId);
            if (recordFound)
                this.selectedRecords.splice(this.selectedRecords.indexOf(recordFound), 1);
        }
        else {
            this.selectedRecords.push(rec);
        }
    }

    allSelected(): boolean {
        var notAllChecked = this.requests.find((x: RequestItemModel) => this.isValidExpirationDate(x.expiration) && !this.hasExpirationDateError(x.requestId) && !this.selectedCheck.find((y: number) => y == x.requestId));
        return notAllChecked != null;
    }

    hasExpirationDateError(id: number): boolean {
        return this.errors.has('expirationDate' + id);
    }

    hasRecordsToSelect(): boolean {
        return this.requests.find((x: RequestItemModel) => this.isValidExpirationDate(x.expiration) && !this.hasExpirationDateError(x.requestId)) != null;
    }

    selectAll(): void {
        this.bounce = !this.bounce;
        const recordsNotSelected = this.requests.filter((x: RequestItemModel) => this.isValidExpirationDate(x.expiration) && !this.hasExpirationDateError(x.requestId) &&
            !this.selectedCheck.find((y: number) => y == x.requestId));

        if (recordsNotSelected.length > 0) {
            recordsNotSelected.forEach((x: RequestItemModel) => {
                this.selectedRecords.push(x);
            });

        } else {
            this.requests.forEach((x: RequestItemModel) => {
                this.selectedRecords.splice(this.selectedRecords.indexOf(x), 1);
            });
        }
    }

    
    removeSelection(requestId: number): void {
        const record = this.selectedRecords.find((x: RequestItemModel) => x.requestId == requestId);

        if (record != undefined) {
            this.selectedRecords.splice(this.selectedRecords.indexOf(record), 1);
        }
    }

    submitForm(): void {
        var max = 2000;

        if (!this.selectedRecords || this.selectedRecords.length == 0) {
            this.errorMessage = 'There are no selected records to submit';
        }
        else if (this.selectedRecords.length > max) {
            this.errorMessage = 'There is a limit of ' + max + ' records per re-request';
        }
        else {

            this.isSubmitting = true;

            var requestModel: RerequestSubmitModel = {
                businessId: this.$store.getters['internal/businessId'],
                requestItems: this.selectedRecords.map(val => ({ requestId: val.requestId, expiration: val.expiration })) as RerequestSubmitItemModel[]
            };
            
            this.selectService.submitRerequest(requestModel).then(response => {
                this.$router.push({ name: 'rerequestconfirmation', query: { requestBatchId: response.data } });
            }).catch(error => {

            }).finally(() => {
                this.isSubmitting = false;
            });
        }
    }

    isNumber(evt: any): boolean {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
            evt.preventDefault();
        } else {
            return true;
        }
        return false;
    }

    refreshPagination(model: PaginationModel): void {
        this.perPage = model.perPage;
        this.currentPage = model.currentPage;
        this.search(false);
    }

    errorOk(): void {
        this.errorMessage = "";
    }

    isValidExpirationDate(expirationDate: any): boolean {
        return dateValidator.validate(expirationDate, null);
    }

}
