import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import BusinessService from '../../../../api-services/business.service';
import { BusinessDetailsModel } from '../../../../model/details.model';

@Component
export default class RequestConfirmation extends Vue {


    private businessService: BusinessService = new BusinessService();

    public requestNumber!: number;
    public businessDetails: BusinessDetailsModel = {} as BusinessDetailsModel;

    mounted(): void {
        this.requestNumber = parseInt(this.$route.query.requestBatchId as string);
        this.businessService.getByBatchId(this.requestNumber).then(resp => {
            this.businessDetails = resp.data;
        })
    }

   


}
