import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import BusinessService from '../../../api-services/business.service';
import VueRecaptcha from 'vue-recaptcha';

@Component({
    props: {
        showModal: Boolean
    },
    components: {
        VueRecaptcha
    }
})
export default class SendInviteCode extends Vue {

    public recaptchaSiteKey: string = "6Lf3mcMUAAAAAOYRBAC9IqOp8mi_PG2T5ku6Egt8";
    public isRecaptchaVerified: boolean | null = null;
    public recaptchaResponse: string = "";
    public isSubmitting: boolean = false;
    public isSuccess: boolean = false;
    public email: string = "";

    businessService: BusinessService = new BusinessService();

    get showModalProp(): boolean {
        return this.$props.showModal;
    }

    mounted(): void {

    }

    modalContactEmailSubmit(): void {
        
        var self = this;
        this.$validator.validateAll().then((isValid: boolean) => {
            
            if (isValid && this.isRecaptchaVerified) {
                self.isSubmitting = true;
                self.businessService.sendInviteCode(self.email, self.recaptchaResponse)
                    .catch(function (error) {
                        self.$router.push({
                            name: 'error'
                        });
                    })
                    .then(function (response) {
                        self.isSubmitting = false;
                        self.isSuccess = true;
                    });
            }

            if (self.isRecaptchaVerified == null)
                self.isRecaptchaVerified = false; 
        });

    }

    public closeModal(): void {
        this.isSuccess = false;
        this.email = "";
        this.resetRecaptcha();
        this.$emit('closeModelAction');
    }

    onVerify(response: any) {
        if (response) {
            this.isRecaptchaVerified = true;
            this.recaptchaResponse = response;
        }
    }

    onExpired() {
        this.isRecaptchaVerified = false;
    }

    resetRecaptcha() {
        var recaptcha: any = this.$refs.recaptcha;
        recaptcha.reset();
        this.isRecaptchaVerified = null;
    }
}
