import axios, { AxiosPromise } from 'axios';
import Base from '../components/shared/base.service';

export default class UserListService extends Base {
    public constructor() {
        super();
    }

    public getUser(userId: number): AxiosPromise {
        return this.axiosHttp.get('/api/user', {
            params: {
                id: userId
            }
        })
    }

}