import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import vueDropzone from 'vue2-dropzone';
import Loading from 'vue-loading-overlay';
import ErrorModal from '../../../components/errors/modal/errormodal.vue';

@Component({
    components: {
        vueDropzone,
        Loading,
        ErrorModal
    }
})

export default class UploadSubopoena extends Vue {
    public errorMessage: any = '';
    public isLoading: boolean = false;

    private dataUploadDropzone: any;

    public dropzoneOptions: any = {
        url: '/api/request/submit',
        thumbnailWidth: 150,
        maxFilesize: 100,
        autoProcessQueue: false,
        addRemoveLinks: true,
        acceptedFiles: '.pdf',
        timeout: 900000,
        maxFiles: 1, 
        dictDefaultMessage: "Drag and drop files or click here to attach files"
    }

    get showErrorModal(): boolean {
        return this.errorMessage.length > 0;
    }

    errorOk(): void {
        this.errorMessage = '';
    }

    mounted(): void { 
        if (!this.$store.getters['newrequest/requestData'])
            this.$router.push({ name: "requestnew" });

        this.dataUploadDropzone = this.$refs.dataUploadDropzone;
    }

    submit(): void {
        if (this.dataUploadDropzone.getQueuedFiles().length == 0) {
            this.errorMessage = "Please add a file to upload"
        }
        else {
            this.dataUploadDropzone.processQueue();
        }
    }

    maxFilesError(file: any): void {
        this.dataUploadDropzone.removeFile(file);
        this.errorMessage = "There is a maximum limit of one PDF file."
    }

    uploadSuccess(file: any, response: any): void {
        if (new URL(file.xhr.responseURL).pathname != this.dropzoneOptions.url) {
            window.location.replace(file.xhr.responseURL);
        } else {
            this.$router.push({ name: 'requestconfirmation', query: { requestBatchId: response } });
        }
    }

    uploadError(file: any, message: any, xhr: any): void {
        this.isLoading = false;
        if (xhr.status == 422) {
            if (message.errorMessage) {
                this.errorMessage = message.errorMessage;
            }
            else {
                this.errorMessage = "A System error has occured."
            }

            this.dataUploadDropzone.removeAllFiles();
        }
        else {
            this.errorMessage = "A System error has occured."
        }
    }

    sendingFile(file: any, xhr: any, formData: any): void {
        this.isLoading = true;
        
        formData.append('subpoenaData', JSON.stringify(this.$store.getters['newrequest/requestData']))

        formData.append('businessId', this.$store.getters['internal/businessId']);
    }

}
