import axios, { AxiosPromise } from 'axios';
import Base from './base.service';

export default class UserRoleService extends Base {

    public constructor() {
        super();
    }

    public getUserRoles() {
        return [
            {
                id: 6,
                value: "Collection Admin",
            },
            {
                id: 7,
                value: "Collection User",
            }
        ];
    }
    
}