import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { RequestSearchCriteria } from '../../../model/requestsearchcriteria.model';
import { RequestSearch } from '../../../model/requestsearch.model';
import RequestSearchService from '../../../api-services/requestsearch.service';
import ErrorModal from '../../../components/errors/modal/errormodal.vue';

@Component(
    {
        components: {
            ErrorModal
        }
    }
)
export default class SearchRequest extends Vue {
    public searchCriteria: RequestSearchCriteria = {
        firstname: "",
        lastname: "",
        requestBatchId: null
    } as RequestSearchCriteria;
    public requestSearchItems: RequestSearch[] = [];

    public apiService: RequestSearchService = new RequestSearchService();
    public errorMessage: string = "";
    public isBusy: boolean = false;
    public windowWidth = window.innerWidth;

    public fields: any = [
        {
            key: 'lastFourSSN',
            thStyle: { maxWidth: '5%' },
            label: 'Last 4 of SSN',
            sortOrder:1
        },
        {
            key: 'reportedName',
            thStyle: { maxWidth: '10%' },
            label: 'Reported name',
            sortOrder: 2
        },
        {
            key: 'requestorName',
            thStyle: { maxWidth: '10%' },
            label: 'Requestor name',
            sortOrder: 3
        },
        {
            key: 'courtNumber',
            thStyle: { maxWidth: '5%' },
            label: 'Court number',
            sortOrder: 4
        },
        {
            key: 'accountNumber',
            thStyle: { maxWidth: '5%' },
            label: 'Account number',
            sortOrder: 5
        },
        {
            key: 'wage',
            thStyle: { maxWidth: '5%' },
            label: 'Wage',
            sortOrder: 6
        },
        {
            key: 'totalHours',
            thStyle: { maxWidth: '5%' },
            label: 'Total hours',
            sortOrder: 7
        },
        {
            key: 'yearQuarter',
            thStyle: { maxWidth: '5%' },
            label: 'Year/QTR',
            sortOrder: 8
        },
        {
            key: 'employerName',
            thStyle: { maxWidth: '10%' },
            label: 'Employer name',
            sortOrder: 9
        },
        {
            key: 'employerAddress',
            thStyle: { maxWidth: '10%' },
            label: 'Employer address',
            sortOrder: 10
        },
        {
            key: 'comments',
            thStyle: { maxWidth: '10%' },
            label: 'Comments',
            sortOrder: 11
        }
    ]

    get checkBoxColumns(): any {
        var filtered = this.fields
            .filter(x => x.label != '')
            .map(function (obj) {
                return { value: obj.key, text: obj.label };
            });
        return filtered;
    }

    get filteredFields(): any {
        let filtered = this.selected.map((obj) => this.fields.find(x => x.key == obj));

        return filtered.sort(function (a, b) {
            return a.sortOrder - b.sortOrder
        });
    }

    public selected = ['lastFourSSN', 'reportedName', 'requestorName', 'courtNumber', 'accountNumber', 'yearQuarter', 'employerName', 'comments'];


    get showErrorModal() {
        return this.errorMessage != "";
    }

    errorOk(): void {
        this.errorMessage = "";
    }

    isSearchEmpty(): boolean {
        return this.searchCriteria.requestBatchId == null && this.searchCriteria.firstname.trim() === '' && this.searchCriteria.lastname.trim() === '';
    }

    search(): void {
        var self = this;
        this.$validator.validateAll().then((isValid: boolean) => {
            if (this.isSearchEmpty()) {
                this.errorMessage = "You must provide a request number or first and last name"
            } else {
                if (isValid) {
                    this.isBusy = true;
                    this.apiService.get(this.searchCriteria).then(resp => {
                        this.requestSearchItems = resp.data;
                    }).catch(error => {
                        if (error.response.status == 400) {
                            self.errorMessage = error.response.data[""][0];
                        } else {
                            self.errorMessage = "A system error has occured";
                        }
                    }).then(x => {
                        this.isBusy = false;
                    });
                }
            }
        });
    }

    exportCSV(): void {
        var self = this;
        this.$validator.validateAll().then((isValid: boolean) => {
            if (this.isSearchEmpty()) {
                this.errorMessage = "You must complete a search before exporting to a csv."
            } else {
            if (isValid) {
                let url = "/api/requestsearch/csv?";
                if (self.searchCriteria.firstname.length > 0)
                    url = url + "&firstname=" + self.searchCriteria.firstname
                if (self.searchCriteria.lastname.length > 0)
                    url = url + "&lastname=" + self.searchCriteria.lastname
                if (self.searchCriteria.requestBatchId)
                    url = url + "&requestBatchId=" + self.searchCriteria.requestBatchId

                window.location.href = url;
            }
            }  
        });
    }
}
