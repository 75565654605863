import { AxiosPromise } from 'axios';
import Base from '../components/shared/base.service';
import { RequestEntry } from '../model/requestentry.model';

export default class RequestService extends Base {
    public constructor() {
        super();
    }

    public submit(subpoenaData: RequestEntry[], businessId: string): AxiosPromise {

        var bodyFormData = new FormData();
        bodyFormData.append('subpoenaData', JSON.stringify(subpoenaData));
        bodyFormData.append('businessId', businessId);

        return this.axiosFormData.post('/api/request/submit', bodyFormData);
    }

    public cancel(requestBatchId: number): AxiosPromise {
        return this.axiosHttp.post('/api/request/cancel', requestBatchId);
    }
}