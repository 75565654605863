import { AxiosPromise } from 'axios';
import Base from '../components/shared/base.service';
import { VerifyRequestItemModel } from '../model/verify.request.item.model';
import { SubmitVerificationModel } from '../model/submit.verification.model';

export default class VerifyService extends Base {

    public constructor() {
        super();
    }

    public getVerificationView(batchId: number, perPage: number, currentPage: number): AxiosPromise {
        return this.axiosHttp.get('/api/requestverification/view', {
            params: {
                requestBatchId: batchId,
                currentPage: currentPage,
                perPage: perPage
            }
        });
    }

    public getVerifyList(batchId: number, perPage: number, currentPage: number): AxiosPromise {
        return this.axiosHttp.get('/api/requestverification/list', {
            params: {
                requestBatchId: batchId,
                currentPage: currentPage,
                perPage: perPage
            }
        });
    }

    public saveVerification(request: VerifyRequestItemModel): AxiosPromise {
        return this.axiosHttp.post('/api/requestverification/save', request);
    }

    public submitVerifications(request: SubmitVerificationModel): AxiosPromise {
        return this.axiosHttp.post('/api/requestverification/submit', request);
    }

    public invalidateAll(requestBatchId: number): AxiosPromise {
        return this.axiosHttp.post('/api/requestverification/invalidate', requestBatchId);
    }

    public validateAll(requestBatchId: number): AxiosPromise {
        return this.axiosHttp.post('/api/requestverification/validate', requestBatchId);
    }

    public updateAllLimited(requestBatchId: number, isLimited: boolean): AxiosPromise {
        return this.axiosHttp.post('/api/requestverification/limited', {
            requestBatchId: requestBatchId,
            isLimited: isLimited
        })
    }

    public isBatchRowsCompleted(batchId: number): AxiosPromise {
        return this.axiosHttp.get('/api/requestverification/isBatchRowsCompleted', {
            params: {
                requestBatchId: batchId
            }
        });
    }

    public voidRequestBatch(requestBatchId: number): AxiosPromise {
        return this.axiosHttp.post('/api/requestverification/void', requestBatchId);
    }

}