import { configName } from '../names'
import axios from 'axios'

export const config = {
    namespaced: true,
    state: {
        sawUrl: "",
        spEntityId: "",
        isConfigSet: false,
        idpEntityId: "",
    },
    getters: {
        sawUrl(state: any): string {
            return state.sawUrl;
        },
        isConfigSet(state: any): string {

            return state.isConfigSet;
        },
        spEntityId(state: any): string {
            return state.spEntityId;
        },

        idpEntityId(state: any): string {
            return state.idpEntityId;
        },

    },

    actions: {
        async loadConfig(context: any) {
            if (!context.getters[configName.isConfigSet]) {
                await axios.get('/api/config').then(response => {
                    context.commit('setConfig', response.data)
                });
            }
        },
    },

    mutations: {
        setConfig(state: any, config: any) {
            state.sawUrl = config.sawUrl;
            state.spEntityId = config.spEntityId;
            state.idpEntityId = config.idpEntityId
            state.isConfigSet = true;
        }
    }
}

