import { BusinessDetailsModel } from '../../model/details.model';
import { RequestEntry } from '../../model/requestentry.model';
import { RequestUploadError } from '../../model/request.upload.error.model';

export const newrequest = {
    namespaced: true,
    state: {
        requestData: null,
        uploadErrors: null

    },
    getters: {
        requestData(state: any): RequestEntry[] {
            return state.requestData;
        },

        hasRequests(state: any): boolean {
            return state.requestData.length > 0;
        },

        requestUploadErrors(state: any): RequestUploadError[] {
            return state.uploadErrors;
        }
    },

    actions: {
        loadUploadErrors(context: any, uploadErrors: RequestUploadError[]) {
            context.commit('setUploadErrors', uploadErrors);
        },
        loadNewRequest(context: any, newRequestData: RequestEntry[]) {
            context.commit('setNewRequest', newRequestData)
        },
    },

    mutations: {
        setUploadErrors(state: any, requestErrors: RequestUploadError[]) {
            state.uploadErrors = requestErrors;
        },

        setNewRequest(state: any, newRequestData: RequestEntry[]) {
            state.requestData = newRequestData;
        },
    }
}

