import { render, staticRenderFns } from "./businessdetails.vue?vue&type=template&id=bec001dc&scoped=true&"
import script from "./businessdetails.ts?vue&type=script&lang=js&"
export * from "./businessdetails.ts?vue&type=script&lang=js&"
import style0 from "./businessdetails.css?vue&type=style&index=0&id=bec001dc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bec001dc",
  null
  
)

export default component.exports