import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import axios from 'axios';


@Component
export default class RoleUpdate extends Vue {

    public businessOptions: any = [] as any;
    public businessId: number | null = null;
    public roleId: number = 6;
    public roleOptions: any =  [
        { value: '7', text: 'User' },
        { value: '6', text: 'Admin' }
      
    ]


    mounted() {
        axios.get('/api/roleupdate/businesses').then(resp => {
            this.businessOptions = resp.data.map((x: any) => {
                return { value: x.businessId, text: x.businessName };
            })
            this.businessOptions.splice(0, 0, { value: null, text: "Please select a business"})
        }).catch(error => {
            this.$router.push({ name: "error" });
        })
    }

    clearRole() {
        axios.get('/api/roleupdate/clear').then(resp => {
            window.location.href = '/home/signout';
        }).catch(error => {
            this.$router.push({ name: "error" });
        })
    }

    setStaff() {
        axios.get('/api/roleupdate/setstaff').then(resp => {
            window.location.href = '/home/signout';
        }).catch(error => {
            this.$router.push({ name: "error" });
        })
    }

    setBusiness() {
        axios.get('/api/roleupdate/setbusiness', {
            params: {
                businessId: this.businessId,
                roleId: this.roleId
            }
        }).then(resp => {
            window.location.href = '/home/signout';
        }).catch(error => {
            this.$router.push({ name: "error" });
        })
    }
    loginAzure() {
        window.location.href = '/home/stafflogin/'
    }
}
