import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { userStoreName, configName } from '../../store/names';
import { PermissionsEnum } from '../../constants/permissions'
import AlertService from '../../api-services/alert.service';
import AlertRoutes from '../../constants/alertroutes';
import { AlertModel } from '../../model/alert.model';
import { AlertPriority } from '../../constants/alertpriority';

@Component
export default class ExternalLogin extends Vue {

    public alertService: AlertService = new AlertService();
    public alerts: AlertModel[] = [];

    mounted(): void {
        this.loadAlertMessage();
        if (!this.$store.getters[userStoreName.isLoaded]) {
            this.$store.dispatch(userStoreName.loadUser).then(x => {
                this.redirectUser();
            })
           
        } else {
            this.redirectUser();
        }

    }

    alertVariant(alert: AlertModel): string {
        return alert.priority == AlertPriority.Danger ? 'danger' : 'info';
    }

    alertIcon(alert: AlertModel): string {
        return alert.priority == AlertPriority.Danger ? 'exclamation-triangle': 'info-circle'
    }

    redirectUser(): void {
        if (this.$store.getters[userStoreName.isLoaded]) {

            let businessId = this.$store.getters[userStoreName.businessId]

            if (this.$store.getters[userStoreName.permissions].includes(PermissionsEnum.StaffAdmin)) {
                this.$router.push({ name: 'internaldashboard' })
            }
            else if (!businessId) {
                this.$router.push({ name: 'registerbycode' })
            }
            else {
                this.$router.push({ name: 'dashboard' })
            }
        }
    }

    loadAlertMessage(): void {
        this.alertService.get(AlertRoutes.login).then(response => this.alerts = response.data);
    }

    popupCheckAccount(): void {
        window.open(this.sawCheckAccountUrl, "_blank", "width=800,height=600");  

    }


    get sawLoginUrl() {
        return this.$store.getters[configName.idpEntityId] + '/logininitial?RequestBinding=HTTPPost&NameIdFormat=Email&PartnerId=' + this.$store.getters[configName.spEntityId]
    }

    get sawCheckAccountUrl() {
        return this.$store.getters[configName.sawUrl] + 'public/saw/pub/remindAndReset.do';
    }




}
