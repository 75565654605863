import axios, { AxiosInstance } from 'axios';
import Vue from 'vue';


export default class Base {
    protected axiosHttp: AxiosInstance = {} as any;
    protected axiosFormData: AxiosInstance = {} as any;

    public constructor() {

        this.axiosHttp = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('jwtToken')
            }
        });

        this.axiosFormData = axios.create({
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + sessionStorage.getItem('jwtToken')
            }
        });
    }

}