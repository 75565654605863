import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import BusinessService from '../../../api-services/business.service';
import RequestDetailsService from '../../../api-services/requestdetails.service';
import { BusinessDetailsModel } from '../../../model/details.model';
import { RequestDetailsModel } from '../../../model/request.details.model';
import { PaginationModel } from '../../../model/pagination.model';
import Pagination from '../../../components/pagination/pagination.vue';
import BackButton from '../../../components/backbutton/backbutton.vue';
import RequestService from '../../../api-services/request.service';
import ErrorModal from '../../../components/errors/modal/errormodal.vue';
import { RequestBatchStatusEnum } from '../../../constants/requestbatchstatus';
import dateFormatter from '../../../formatter/dateFormatter';
import phoneFormatter from '../../../formatter/phoneFormatter';

@Component({
    components: {
        Pagination,
        BackButton,
        ErrorModal
    },
    filters: {
        dateFormatter,
        phoneFormatter
    }
})
export default class RequestDetails extends Vue {

    public businessId: number = 0;
    public requestBatchId: number = 0;
    public businessDetailsModel: BusinessDetailsModel = {} as BusinessDetailsModel;
    public requestDetailsModel: RequestDetailsModel = {} as RequestDetailsModel;
    public businessService: BusinessService = new BusinessService();
    public requestDetailsService: RequestDetailsService = new RequestDetailsService();
    public requestService: RequestService = new RequestService();
    public currentPage = 1;
    public perPage = 10;
    public pageOptions = [10, 20, 50];
    public totalRows = 1;
    public isBusy: boolean = false;
    public errorMessage: string = "";
    public windowWidth = window.innerWidth;


    public fields: any = [
        { key: 'requestedBy', label:'Requestor', sortOrder:1},
        { key: 'courtNumber', label: 'Court number', sortOrder: 2},
        { key: 'requestedName', label: 'Request name', sortOrder: 3},
        { key: 'lastFourOfSSN', label: 'Last 4 of SSN', sortOrder: 4},
        { key: 'accountCaNumber', label: 'Account number', sortOrder: 5 },
        { key: 'yearQuarter', label: 'Year/QTR', sortOrder: 6 },
        { key: 'reportedName', label: 'Reported name', sortOrder: 7},
        { key: 'wage', label: 'Wage', sortOrder: 8},
        { key: 'hours', label: 'Hours', sortOrder: 9 },
        { key: 'employerName', label: 'Employer name', sortOrder: 10 },
        { key: 'employerAddress', label: 'Employer address', sortOrder: 11},
        { key: 'employerPhone', label: 'Employer phone', sortOrder: 12},
        { key: 'comments', label: 'Comments', sortOrder: 13}
    ];

    get checkBoxColumns(): any {
        var filtered = this.fields
            .filter(x => x.label != '')
            .map(function (obj) {
                return { value: obj.key, text: obj.label };
            });
        return filtered;
    }

    get filteredFields(): any {
        let filtered = this.selected.map((obj) => this.fields.find(x => x.key == obj));

        return filtered.sort(function (a, b) {
            return a.sortOrder - b.sortOrder
        });
    }

    public selected = ['requestedBy', 'courtNumber', 'requestedName', 'lastFourOfSSN', 'accountCaNumber', 'yearQuarter', 'reportedName', 'employerName', 'employerAddress', 'comments'];


    get showErrorModal(): boolean {
        return this.errorMessage.length > 0;
    }

    get isRequestSubmitted(): boolean {
        return this.requestDetailsModel.requestBatchStatusId == RequestBatchStatusEnum.Submitted;
    }

    get isRequestCanceled(): boolean {
        return this.requestDetailsModel.requestBatchStatusId == RequestBatchStatusEnum.Canceled;
    }

    get isRequestCompleted(): boolean {
        return this.requestDetailsModel.requestBatchStatusId == RequestBatchStatusEnum.Completed;
    }

    mounted(): void {
        this.isBusy = true;
        this.businessId = parseInt(this.$route.query.businessId as string);
        this.requestBatchId = parseInt(this.$route.query.requestBatchId as string);

        this.businessService.get(this.businessId).then(x => {
            this.businessDetailsModel = x.data;
        })
        this.requestDetailsService.get(this.requestBatchId).then(x => {
            this.isBusy = false;
            this.requestDetailsModel = x.data;
            this.totalRows = x.data.items.length;
        })
        .catch(x => {
            this.$router.push({ name: 'error' });
        }).then(x => {
            this.isBusy = false;
        });

    }

    refreshPagination(model: PaginationModel): void {
        this.perPage = model.perPage;
        this.currentPage = model.currentPage;
    }

    clickCancelRequest(): void {
        this.requestService.cancel(this.requestBatchId).then(response => {
            this.requestDetailsModel.requestBatchStatusId = RequestBatchStatusEnum.Canceled;
        }).catch(error => {
            this.errorMessage = "System error occurred"
        });
    }

    clickBilling(): void {
        var location = { name: 'requestbilling', query: { requestBatchId: this.requestBatchId } } as any

        if (this.businessId > 0) {
            location.query.businessId = this.businessId;
        }

        this.$router.push(location);
    }

    errorOk(): void {
        this.errorMessage = "";
    }
}
