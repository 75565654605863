import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import axios from 'axios';
import { PermissionsEnum } from '../../constants/permissions'
import { userStoreName, internal } from '../../store/names';
@Component
export default class NavMenuComponent extends Vue {

    mounted(): void {
    }

    get username(): string {
        let user = this.$store.getters['user/username'];

        if (!user) {
            this.$store.dispatch('user/loadUser');
        }

        return this.$store.getters['user/username'];
    }

    get permissions(): any {
        return this.$store.getters['user/permissions'];
    }

    get actingBusinessName(): any {
        return this.$store.getters['internal/businessName']
    }

    get hasActingBusinessName(): any {
        if (this.$store.getters['internal/businessName'])
            return this.$store.getters['internal/businessName'].length > 0;

        return false;
    }

    get isStaff(): boolean {
        return this.permissions.indexOf(PermissionsEnum.StaffAdmin) > -1;
    }

    get isAccountAdmin(): boolean {
        return this.permissions.indexOf(PermissionsEnum.AccountAdmin) > -1;
    }

    get isAccountUser(): boolean {
        return this.permissions.indexOf(PermissionsEnum.AccountRequest) > -1;
    }

    get hasProfile(): boolean {
        return this.permissions.length > 0 && !this.isStaff;
    }

    get showMenu(): boolean {
        return this.$store.getters[userStoreName.businessId] || this.$store.getters[userStoreName.isStaff]
    }

    signOut() {
            window.location.href = '/home/signout';
        
    }

    removeImpersonation(): void {
        this.$store.dispatch(internal.clearImpersonation).then(x => {
            window.location.href = '/internal/dashboard';
        })
    }

}
