import moment from 'moment'


export const dateValidator = {
    getMessage(field: any, args: any): string {
        return "Invalid Date";
    },
    validate(value: any, args: any): boolean {
        
        if (value) {
            if (value.length == 10) {
                var date = moment(value, 'MM/DD/YYYY', true);
            } else if (value.length == 8) {
                var date = moment(value, 'MM/DD/YY', true);
            } else {
                return false;
            }
            return date.isValid() && date.isSameOrAfter(moment().startOf('date')) && date.year() < 2100;
        }

        return false;
    }

};

