import { RequestEntry } from '../../model/requestentry.model';
import { RequestUploadError } from '../../model/request.upload.error.model';

export const rerequest = {
    namespaced: true,
    state: {
        reRequestData: null,
        uploadErrors: null

    },
    getters: {
        rerequest(state: any): RequestEntry[] {
            return state.reRequestData;
        },
        requestUploadErrors(state: any): RequestUploadError[] {
            return state.uploadErrors;
        }
    },

    actions: {
        loadUploadErrors(context: any, uploadErrors: RequestUploadError[]) {
            context.commit('setUploadErrors', uploadErrors);
        },
        loadRerequest(context: any, rerequestData: RequestEntry[]) {
            context.commit('setRerequest', rerequestData);
        },
        clearRerequest(context: any) {
            context.commit('clearRerequest')
        }
    },

    mutations: {
        setRerequest(state: any, reRequestData: RequestEntry[]) {
            state.reRequestData = reRequestData;
        },

        setUploadErrors(state: any, requestErrors: RequestUploadError[]) {
            state.uploadErrors = requestErrors;
        },

        clearRerequest(state: any) {
            state.reRequestData = null;
        },
    }
}

