import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { userStoreName } from '../../../store/names'

@Component({})
export default class RequestConfirmation extends Vue {
    public requestNumber: any = '';
    public isStaff: boolean = false;
    mounted(): void {
        this.isStaff = this.$store.getters[userStoreName.isStaff];
        this.requestNumber = this.$route.query.requestBatchId;
    }

}
