import axios, { AxiosInstance, AxiosPromise } from 'axios';
import { PermissionsEnum } from '../../constants/permissions'

export const user = {
    namespaced: true,
    state: {
        firstname: "",
        lastname: "",
        username: "",
        businessId: "",
        permissions: <number[]>[]
    },
    getters: {
        permissions(state: any): number[] {
            return state.permissions;
        },

        username(state: any): string {
            return state.username;
        },

        businessId(state: any): string {
            return state.businessId;
        },


        isLoaded(state: any): boolean {
            if (!state.username)
                return false;

            return state.username.length > 0
        },

        isStaff(state: any): boolean {
            return state.permissions.indexOf(PermissionsEnum.StaffAdmin) > -1;
        }
    },

    actions: {
        async loadUser(context: any) {
            if (!context.getters['isLoaded']) {
                await axios.get('/api/login').then(response => {
                    context.commit('setUser', response.data)
                });
            }
        },

        async refreshUser(context: any) {
             await axios.get('/api/login').then(response => {
                 context.commit('setUser', response.data)
             });
        },

        async signOut(context: any) {
            sessionStorage.clear();
            context.commit('clearUser');
        }
    },

    mutations: {
        setUser(state: any, user: any) {
            state.firstname = user.firstname;
            state.lastname = user.lastname;
            state.permissions = user.permissions;
            state.username = user.username;
            state.businessId = user.businessId
        },

        clearUser(state: any) {
            state.firstname = "";
            state.lastname = "";
            state.permissions = "";
            state.username = "";
            state.businessId = "";
        }
    },
}

