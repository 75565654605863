import axios, { AxiosInstance, AxiosPromise } from 'axios';
export const business = {

    namespaced: true,
    state: {
        businessDetails: false
    },
    getters: {
        getBusinessDetails(state: any) {
            return state.businessDetails;

        }
    },

    actions: {
        async loadDetails(context: any, businessDetails: any) {
            context.commit('setBusiness', businessDetails);
        }
    },

    mutations: {
        setBusiness(state: any, businessDetails: any) {
            state.businessDetails = businessDetails;
        }
    }
}

