import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import axios from 'axios';
import AccountMenu from '../../components/accountmenu/accountmenu.vue';
import Pagination from '../../components/pagination/pagination.vue';
import { RequestBatchStatusEnum } from '../../constants/requestbatchstatus';
import RequestService from '../../api-services/request.service';
import ErrorModal from '../../components/errors/modal/errormodal.vue';
import { CustomerDashboardModel } from '../../model/customer.dashboard.model';
import dateFormatter from '../../formatter/dateFormatter';
import AlertService from '../../api-services/alert.service';
import AlertRoutes from '../../constants/alertroutes';
import { AlertModel } from '../../model/alert.model';
import { AlertPriority } from '../../constants/alertpriority';

@Component({
    components: {
        AccountMenu,
        Pagination,
        ErrorModal
    }
})
export default class DashboardCustomer extends Vue {

    public requestService: RequestService = new RequestService();
    public isBusy: boolean = false;
    public errorMessage: string = "";
    public windowWidth = window.innerWidth;
    public list = true;  // true = less selected by default and show checkboxes, false = all selected and don't show checkboxes
    public alerts: AlertModel[] = [];
    public alertService: AlertService = new AlertService();

    public fields: any = [
        { label: 'Id', key: 'requestBatchId', tdClass: 'text-md-center text-right pr-4', sortOrder: 1 },
        { label: 'Request Count', key: 'requestCount', tdClass: 'text-md-center text-right pr-4', sortOrder: 2 },
        { label: 'Submitted', key: 'requestDate', formatter: dateFormatter, sortOrder: 3, tdClass: 'text-md-center text-right pr-4' },
        { label: 'Status', key: 'status', sortOrder: 4, tdClass: 'text-md-center text-right pr-4' },
        { label: 'Last Activity Date', key: 'lastActivityDate', formatter: dateFormatter, sortOrder: 5, tdClass: 'text-md-center text-right pr-4' },
        { label: '', key: 'action', thStyle: { width: '300px' }, sortOrder: 6 }
    ]

    get checkBoxColumns(): any {
        var filtered = this.fields
            .filter(x => x.label != '')
            .map(function (obj) {
                return { value: obj.key, text: obj.label };
            });
        return filtered;
    }

    get filteredFields(): any {
        let filtered = this.selected.map((obj) => this.fields.find(x => x.key == obj));

        return filtered.sort(function (a, b) {
            return a.sortOrder - b.sortOrder
        });
    }

    public selected = [ 'requestBatchId', 'requestCount', 'requestDate',  'status' ,  'lastActivityDate', 'action'];


    public dashboardView: CustomerDashboardModel = { requestItems: [], requestsNotViewed: [] } as CustomerDashboardModel;

    get totalRows(): number {
        return this.dashboardView.requestItems.length;
    }

    get showErrorModal(): boolean {
        return this.errorMessage.length > 0;
    }

    alertVariant(alert: AlertModel): string {
        return alert.priority == AlertPriority.Danger ? 'danger' : 'info';
    }

    alertIcon(alert: AlertModel): string {
        return alert.priority == AlertPriority.Danger ? 'exclamation-triangle' : 'info-circle'
    }

    mounted(): void {
        this.loadAlertMessage();
        this.loadDashboard();
    }

    requestComplete(statusId: number): boolean {
        return statusId == RequestBatchStatusEnum.Completed;
    }

    requestSubmitted(statusId: number): boolean {
        return statusId == RequestBatchStatusEnum.Submitted;
    }

    hasNotices(): boolean {
        return this.dashboardView.requestsNotViewed.length > 0;
    } 


    clickCancelRequest(requestBatchId: number): void {
        this.requestService.cancel(requestBatchId).then(response => {
            this.loadDashboard();
        }).catch(error => {
            this.errorMessage = "System error occurred"
        });
    }

    loadAlertMessage(): void {
        this.alertService.get(AlertRoutes.dashboard).then(response => this.alerts = response.data);
    }

    loadDashboard(): void {

        this.isBusy = true;

        axios.get('/api/customerdashboard', {}).then(response => {
            this.isBusy = false;
            this.dashboardView = response.data;
        }).catch(x => {
            this.$router.push({ name: "error" });
        }).then(x => {
            this.isBusy = false;
        });
    }

    errorOk(): void {
        this.errorMessage = "";
    }
}
