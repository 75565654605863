//css
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-loading-overlay/dist/vue-loading.css';
import './css/site.css';

//ie compatability
import 'promise-polyfill/src/polyfill';
import 'es6-shim';
import 'core-js/stable'
import 'regenerator-runtime/runtime'

//components
import App from './App.vue';

//main
import Vue from 'vue';
import router from './router';
import BootstrapVue from 'bootstrap-vue';
import { store } from './store/store';
import VeeValidate from 'vee-validate';
import Vue2Filters from 'vue2-filters';
import Vidle from 'v-idle'

//fonts
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

Vue.use(Vue2Filters);
Vue.use(VeeValidate, { fieldsBagName: 'veeFields' });
library.add(fas);
Vue.use(BootstrapVue);
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false;
Vue.use(Vidle);


var vue = new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app');