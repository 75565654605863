import Vuex, { StoreOptions } from 'vuex';
import { user } from './modules/user';
import { newrequest } from './modules/newrequest';
import { internal } from './modules/internal';
import { business } from './modules/business';
import { rerequest } from './modules/rerequest';
import { config } from './modules/config';
import Vue from 'vue';
import VuexPersistence from 'vuex-persist';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
    storage: window.sessionStorage
})

export const storeOptions: StoreOptions<any> = {
    modules: {
        user,
        newrequest,
        internal,
        business,
        rerequest,
        config
    },
    plugins: [vuexLocal.plugin]
}

export const store = new Vuex.Store<any>(storeOptions);

export default store;


