import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import axios from 'axios';


@Component
export default class ExternalLogin extends Vue {

    mounted(): void {
        this.$store.dispatch('user/signOut');
    }
}