import axios, { AxiosPromise } from 'axios';
import Base from '../components/shared/base.service';

export default class BillingService extends Base {
    public constructor() {
        super();
    }

    public get(requestBatchId: number | null): AxiosPromise {
        return this.axiosHttp.get('/api/billing', {
            params: {
                requestBatchId: requestBatchId
            }
        })
    }



}