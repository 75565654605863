import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class DataLayout extends Vue {

    public csvFields: any = [
        { key: 'SSN', label: 'SSN' },
        { key: 'FirstName', label: 'FirstName' },
        { key: 'LastName', label: 'LastName' },
        { key: 'RequestorName', label: 'RequestorName' },
        { key: 'CourtNumber', label: 'CourtNumber' },
        { key: 'ExpirationDate', label: 'ExpirationDate' },
        { key: 'AccountNumber', label: 'AccountNumber' }
    ]

    public dataitems: any = [
        {
            RequestorName: "John",
            CourtNumber: "232",
            SSN: "123-45-6789",
            ExpirationDate: "4/4/2020",
            AccountNumber: "dfdf",
            FirstName: "Mike",
            LastName: "Smith"
        }
    ]

    get exampleCSVFile() {
        return '../example/example.csv';
    }


    public formatItems: any = [
        { column_name: "SSN", max_length: '11', requirements: 'Required field.  Format XXX-XX-XXXX or XXXXXXXXX', description: 'SSN of the individual being requested' },
        { column_name: "FirstName", max_length: '255', requirements: 'Required field', description: 'First name of the individual being requested' },
        { column_name: "LastName", max_length: '255', requirements: 'Required field', description: 'Last name of the individual being requested' },
        { column_name: "RequestorName", max_length: '255', requirements: 'Required field', description: 'Name of the requesting business' },
        { column_name: "CourtNumber", max_length: '255', requirements: 'Optional field', description: 'Court number for the court that approved the subpoena' },
        { column_name: "ExpirationDate", max_length: '10', requirements: 'Required field.  Format MM/DD/YYYY', description: 'Expiration of the subpoena' },
        { column_name: "AccountNumber", max_length: '255', requirements: 'Optional field', description: 'Open field to use as you need. Could be used for the Individual account number with your organization' }
    ]
}
