import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import axios from 'axios';
import { DashboardStaff } from '../../../model/dashboard.staff.model'
import { PaginationModel } from '../../../model/pagination.model';
import Pagination from '../../../components/pagination/pagination.vue';
import feinFormatter from '../../../formatter/feinFormatter';
import dateFormatter from '../../../formatter/dateFormatter';
import datetimeFormatter from '../../../formatter/datetimeFormatter';

@Component({
    filters: {
      datetimeFormatter  
    },
    components: {
        Pagination
    }
})
export default class DashboardInternal extends Vue {

    public currentPage_dashboard = 1;
    public perPage = 10;
    public pageOptions = [10, 20, 50];
    public windowWidth = window.innerWidth;

    public fields: any = [
        { key: 'requestBatchId', label: 'Batch Id', sortable: true, sortOrder: 1 },
        { key: 'businessName', label:'Business', sortable: true, sortDirection: 'desc', sortOrder: 2 },
        { key: 'submitDate', label: 'Submitted', formatter: dateFormatter, sortable: true, class: 'text-center', sortOrder: 3 },
        { key: 'requestorName', label: 'Requestor name', sortOrder: 4 },
        { key: 'requestorEmail', label: 'Requestor email', sortOrder: 5 },
        { key: 'fein', label: 'FEIN', formatter: feinFormatter, sortOrder: 6 },
        { key: 'rerequest', label: 'Re-request', sortOrder: 7 },
        { key: 'status', label: 'Status', sortOrder: 8 },
        { key: 'lastWorkedByName', label: 'Last worked by', sortOrder: 9 },
        { key: 'batchActions', label: '', sortOrder: 10 }
    ];

    public selected = ['requestBatchId', 'businessName', 'submitDate', 'requestorName', 'fein', 'rerequest', 'status', 'batchActions'];

    get checkBoxColumns(): any {
        var filtered = this.fields
            .filter(x => x.label != '')
            .map(function (obj) {
                return { value: obj.key, text: obj.label };
            });
        return filtered;
    }

    get filteredFields(): any {
        let filtered = this.selected.map((obj) => this.fields.find(x => x.key == obj));

        return filtered.sort(function (a, b) {
            return a.sortOrder - b.sortOrder
        });
    }

    public tableLoading: boolean = true;
    public dashboard: DashboardStaff = {
        requests: []
    } as DashboardStaff;

    get totalRows(): number {
        return this.dashboard.requests.length;
    }

    mounted(): void {
        axios.get('/api/StaffDashboard').then(x => {
            this.dashboard = x.data;
        }).catch(x => {
            this.$router.push({ name: "error" });
        }).then(() => {
            this.tableLoading = false;
        })

    }

    refreshPagination(model: PaginationModel): void {
        this.perPage = model.perPage;
        this.currentPage_dashboard = model.currentPage;
    }
}
