export const userStoreName = {
    isStaff: 'user/isStaff',
    isLoaded: 'user/isLoaded',
    permissions: 'user/permissions',
    businessId: 'user/businessId',
    loadUser: 'user/loadUser'

}

export const newRequestStoreName = {
    requestData: 'newrequest/requestData',
    loadNewRequest: 'newrequest/loadNewRequest'
}

export const internal = {
    contactName: 'internal/contactName',
    businessId: 'internal/businessId',
    clearImpersonation: 'internal/clearImpersonation',
    setBusiness: 'internal/setBusiness'
}

export const configName = {
    sawUrl: 'config/sawUrl',
    idpEntityId: 'config/idpEntityId',
    spEntityId: 'config/spEntityId',
    isConfigSet: 'config/isConfigSet',
    loadConfig: 'config/loadConfig'
}