import Vue from 'vue';
import { Component } from 'vue-property-decorator';
@Component
export default class BackButton extends Vue {

    mounted(): void {

    }

    backAction(): void {
        window.history.go(-1);
    }
}
