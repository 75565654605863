import { AxiosPromise } from 'axios';
import Base from '../components/shared/base.service';
import { SearchCriteriaModel } from '../model/rerequest.search.criteria.model';
import { RerequestSubmitModel } from '../model/rerequest.submit.model';

export default class RerequestService extends Base {
    public constructor() {
        super();
    }

    public searchFilter(data: SearchCriteriaModel): AxiosPromise {
        return this.axiosHttp.get('/api/rerequest/search', {
            params: data
        });
    }

    public submitRerequest(rerequest: RerequestSubmitModel): AxiosPromise {
        return this.axiosHttp.post('/api/rerequest/submit', rerequest);
    }

}