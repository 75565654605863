import axios, { AxiosPromise } from 'axios';
import Base from '../components/shared/base.service';
import { RequestSearchCriteria } from '../model/requestsearchcriteria.model';

export default class RequestSearchService extends Base {
    public constructor() {
        super();
    }

    public get(searchCriteria: RequestSearchCriteria): AxiosPromise {
        return this.axiosHttp.get('/api/requestsearch', {
            params: {
                firstname: searchCriteria.firstname,
                lastname: searchCriteria.lastname,
                requestBatchId: searchCriteria.requestBatchId 
            }
        });
    }
}