import Vue from 'vue';
import { Component, Inject } from 'vue-property-decorator';
import axios from 'axios';
import RegisterService from '../../../../api-services/register.service'
import { RegisterBusinessModel } from '../../../../model/businessregister.model'
import RegisterBusiness from '../../../../components/page//businessregister/businessregister.vue';
import { internal } from '../../../../store/names';

@Component({
    components: {
        RegisterBusiness
    }
})
export default class RegisterComponent extends Vue {
    registerService: RegisterService = new RegisterService();
    errorList: any = [];

    mounted(): void {

    }

    submit(model: RegisterBusinessModel): void {
        var self = this;
        this.registerService.submit(model)
            .then(function (response) {
                let contactName = model.contactfirstname + " " + model.contactlastname;
                let businessId = response.data;
                let businessName = model.name

                self.$store.commit(internal.setBusiness, { businessName, contactName, businessId })
                self.errorList = [];
                window.location.href = '/internal/dashboard'
            })
            .catch(function (error) {
                self.errorList = error.response.data;
            });
    }
}
