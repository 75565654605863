import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { BusinessDetailsModel } from '../../../model/details.model';
import BusinessDetailService from '../../../api-services/business.service';
import RequestService from '../../../api-services/request.service';
import { userStoreName, newRequestStoreName } from '../../../store/names'
import vueDropzone from 'vue2-dropzone';
import BusinessDetails from '../../../components/page/businessdetails/businessdetails.vue';
import ErrorModal from '../../../components/errors/modal/errormodal.vue';

@Component({
    components: {
        vueDropzone,
        BusinessDetails,
        ErrorModal
    }
})
export default class RequestNew extends Vue {
    public newRequest: any = {};
    public iAgree: boolean = false;
    public manualEntry: string = 'DragAndDrop';
    public errorMessage: string = "";

    private dataUploadDropzone: any;

    businessDetailsService: BusinessDetailService = new BusinessDetailService();
    requestService: RequestService = new RequestService();

    public dropzoneOptions: any = {
        url: '/api/request/dataupload',
        thumbnailWidth: 150,
        maxFilesize: 500,
        autoProcessQueue: false,
        addRemoveLinks: true,
        acceptedFiles: '.csv',
        maxFiles: 1,
        dictDefaultMessage: "Drag and drop file or click here to attach file"
    }


    public business: BusinessDetailsModel = {} as BusinessDetailsModel;

    mounted(): void {
        this.businessDetailsService.get(this.$store.getters['internal/businessId']).then(x => this.business = x.data)
        this.dataUploadDropzone = this.$refs.dataUploadDropzone;
    }

    next(): void {
   
        if (this.iAgree) {
            if (this.manualEntry === 'ManualUpload') {
                this.$store.dispatch(newRequestStoreName.loadNewRequest, null);
                this.$router.push({ name: 'requestdataentry' })
            }

            if (this.dataUploadDropzone.getQueuedFiles().length === 0)
                this.errorMessage = " Please choose a valid CSV file to upload."

            this.dataUploadDropzone.processQueue();

        } else {
            this.errorMessage = "Please agree to terms"
        }
    }

   


    popOut() {
        var thisUrl = "/request/datalayout";
        var popupWindow = null;
        popupWindow = window.open(thisUrl, 'popUpWindow', 'height=800,width=1700,left=50,top=50,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes');
    }
  
    uploadSuccess(file: any, response: any): void {
        if (this.$store.getters[userStoreName.isStaff]) {
            this.requestService.submit(response, this.$store.getters['internal/businessId']).then(response => {
                this.$router.push({ name: 'requestconfirmation', query: { requestBatchId: response.data } })
            });
        } else {

            this.$store.dispatch(newRequestStoreName.loadNewRequest, response);
            this.$router.push({ name: 'uploadsubpoena' })
        }

    }


    uploadError(file: any, message: any, xhr: any): void {
        if (xhr.status == 422) {
            if (message.errorMessage) {
                this.errorMessage = message.errorMessage;
            }
            else {
                this.$store.dispatch('newrequest/loadUploadErrors', message.rowErrors);
                this.$router.push({ name: 'requestuploaderrors' })
            }
        }
        else {
            this.errorMessage = "A System error has occured."
        }
        this.dataUploadDropzone.removeAllFiles();

    }

    maxFilesError(file: any): void {
        this.dataUploadDropzone.removeFile(file);
        this.errorMessage = "There is a maximum limit of one PDF file."
    }

    errorOk(): void {
        this.errorMessage = "";
    }

    get displayDropzone(): boolean {
        return this.manualEntry == 'DragAndDrop'
    }

    get hasErrors(): boolean {
        return this.errorMessage.length > 0;
    }
}
