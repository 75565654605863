import axios, { AxiosPromise } from 'axios';
import Base from '../components/shared/base.service';
import { ActivitySearchCriteria } from '../model/activity.report.model';

export default class ReportService extends Base {
    public constructor() {
        super();
    }

    public getActivity(searchCriteria: ActivitySearchCriteria): AxiosPromise {
        return this.axiosHttp.get('/api/report/activity', {
            params: {
                beginDate: searchCriteria.beginDate,
                endDate: searchCriteria.endDate
            }
        })
    }



}