import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import { BusinessDetailsModel } from '../../../model/details.model';
import BusinessDetailService from '../../../api-services/business.service';
import vueDropzone from 'vue2-dropzone';
import Loading from 'vue-loading-overlay';
import BusinessDetails from '../../../components/page/businessdetails/businessdetails.vue';
import ErrorModal from '../../../components/errors/modal/errormodal.vue';

@Component({
    components: {
        vueDropzone,
        Loading,
        BusinessDetails,
        ErrorModal
    }
})
export default class RerequestNew extends Vue {

    public iAgree: boolean = false;
    public asOfDate: any = new Date().toLocaleDateString();
    public selectedOption: string = '';
    public errorMessage: string = ""
    public service: BusinessDetailService = new BusinessDetailService();
    public selectedBusiness: BusinessDetailsModel = {} as BusinessDetailsModel
    public isLoading: boolean = false;

    private dataUploadDropzone: any;

    public dropzoneOptions: any = {
        url: '/api/rerequest/dataupload',
        thumbnailWidth: 150,
        maxFilesize: 500,
        autoProcessQueue: false,
        addRemoveLinks: true,
        acceptedFiles: '.csv',
        maxFiles: 1,
        timeout: 60000
    }

    mounted(): void {
       

        this.service.get(this.$store.getters['internal/businessId']).then(x => this.selectedBusiness = x.data);
        this.dataUploadDropzone = this.$refs.dataUploadDropzone;
    }

    popOut() {
        var thisUrl = "/request/datalayout";
        var popupWindow = null;
        popupWindow = window.open(thisUrl, 'popUpWindow', 'height=800,width=1700,left=50,top=50,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes');
    }

    get showErrorModalRerequest(): boolean {
        return this.errorMessage.length > 0;
    }

    next(): void {
        if (!this.selectedOption) {
            this.errorMessage = "You need to select either 'Manual Selection/Search' or 'Upload a data file in CSV format'";
            return;
        }
        
        if (this.iAgree) {
            if (this.selectedOption === 'MANUAL') {
                this.$router.push({ name: "rerequestselect" });
            }
            if (this.dataUploadDropzone.getQueuedFiles().length > 0) {
                this.dataUploadDropzone.processQueue();
            } else {
                this.errorMessage = " Please choose a valid CSV file to upload.";
            } 
        } else {
            this.errorMessage = "Please agree to the terms";
        }
    }

    uploadSuccess(file: any, response: any): void {
        this.isLoading = false;
        this.$router.push({ name: 'rerequestconfirmation', query: { requestBatchId: response } });
    }

    uploadError(file: any, message: any, xhr: any): void {
        this.isLoading = false;
        if (xhr.status == 422) {
            if (message.errorMessage) {
                this.errorMessage = message.errorMessage;
            }
            else {
                this.$store.dispatch('rerequest/loadUploadErrors', message.rowErrors);
                this.$router.push({ name: 'rerequestuploaderrors' });
            }
        }
        else {
            this.errorMessage = "A System error has occured."
        }
        this.dataUploadDropzone.removeAllFiles();

    }

    sendingFile(file: any, xhr: any, formData: any): void {
        formData.append('businessId', this.$store.getters['internal/businessId']);
    }

    maxFilesError(file: any): void {
        this.dataUploadDropzone.removeFile(file);
        this.errorMessage = "There is a maximum limit of one PDF file."
    }

    errorOk(): void {
        this.errorMessage = "";
    }
}
