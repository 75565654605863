import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { PaginationModel } from '../../model/pagination.model';

@Component({
    props: {
        totalRows: Number,
        pageOptions: Array
    }
})
export default class Pagination extends Vue {

    public perPage: number = 0;
    public currentPage: number = 1;


    mounted(): void {
        this.perPage = this.$props.pageOptions[0];
    }
   
    get totalRowsProp(): number {
        return this.$props.totalRows;
    }

    get pageOptionsProp(): number[] {
        return this.$props.pageOptions
    }

    public update(): void {
        this.$emit('update', { perPage: this.perPage, currentPage: this.currentPage } as PaginationModel);
    }

}
