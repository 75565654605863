import { render, staticRenderFns } from "./billing.vue?vue&type=template&id=0ec5b6f2&scoped=true&"
import script from "./billing.ts?vue&type=script&lang=js&"
export * from "./billing.ts?vue&type=script&lang=js&"
import style0 from "./billing.css?vue&type=style&index=0&id=0ec5b6f2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ec5b6f2",
  null
  
)

export default component.exports