import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import axios from 'axios';


@Component
export default class StaffSignon extends Vue {


   

    loginAzure() {
        window.location.href = '/home/stafflogin/'
    }
}
