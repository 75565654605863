import Vue from 'vue';
import { Component, Inject } from 'vue-property-decorator';
import axios from 'axios';
import { BusinessDetailsModel } from '../../../model/details.model';
import BusinessService from '../../../api-services/business.service';
import { RegisterBusinessModel } from '../../../model/businessregister.model';
import { configName, internal } from '../../../store/names'
import AccountMenu from '../../../components/accountmenu/accountmenu.vue';
import RegisterBusiness from '../../../components/page//businessregister/businessregister.vue';
import LoadingSpinner from '../../../components/loading/loading.vue';
import phoneFormatter from '../../../formatter/phoneFormatter';
import feinFormatter from '../../../formatter/feinFormatter';
import ErrorModal from '../../../components/errors/modal/errormodal.vue';

@Component({
    components: {
        AccountMenu,
        RegisterBusiness,
        LoadingSpinner,
        ErrorModal
    },
    filters: {
        phoneFormatter,
        feinFormatter
    }
})
export default class BusinessDetailsComponent extends Vue {
    public errorList: string[] = [] as string[];
    public businessEditModalShow: boolean = false;
    public businessDetailsModel: BusinessDetailsModel = {} as BusinessDetailsModel;
    public registerBusiness: RegisterBusinessModel = {} as RegisterBusinessModel;
    public rerenderKey: number = 0;
    public errorMessage: string = "";
    public emailSentModalShow: boolean = false;
    public sendInviteCodeSpinner: boolean = false;
    public deleteErrors: string[] = [] as string[];
    public deleteLoading: boolean = false;

    service: BusinessService = new BusinessService();

    get permissions(): any {
        return this.$store.getters['user/permissions'];

    }

    get isStaff(): boolean {
        return this.permissions.indexOf(1) > -1;
     
    }

    get isAccountAdmin(): boolean {
        return this.permissions.indexOf(10) > -1;
    }

    get canEdit(): boolean {
        return this.isStaff || this.isAccountAdmin;
    }

    get showVerifyEmail(): boolean {
        return this.isStaff && !this.businessDetailsModel.contactEmailVerified;
    }

    sawUpdateAccountUrl(): void {
        window.open(this.$store.getters[configName.sawUrl] + 'myAccess/saw/myFortress/profileDisplay.do', "Saw update profile", 'width=800px,height=1000px')
    }

    sendInviteCode(): void {
        if (this.businessDetailsModel.contactEmail) {
            this.sendInviteCodeSpinner = true;
            axios.get('/api/business/invitecode', {
                params: {
                    businessId: this.businessDetailsModel.businessId
                }
            }).then(response => {
                this.emailSentModalShow = true;
            }).catch((error: any) => {
                this.errorMessage = "Sorry, a system error has occured."
            }).then(response => {
                this.sendInviteCodeSpinner = false;
            });
        }
        else {
            this.errorMessage = 'Please provide a contact email for this business.';
        }
    }

    deleteBusiness(bvEvent): void {
        bvEvent.preventDefault();
        this.deleteLoading = true;
        this.service.deleteBusiness(this.businessDetailsModel.businessId)
            .then(response => {
                this.deleteLoading = false;
                this.$store.dispatch(internal.clearImpersonation).then(x => {
                    window.location.href = '/internal/dashboard';
                });
            })
            .catch(error => {
                if (error.response.status == 422) {
                    this.$bvModal.hide('delete-business');
                    this.deleteErrors = error.response.data;
                } else {
                    this.$router.push({ name: 'error' });
                }
            });
    }

    get showErrorModal(): boolean {
        return this.errorMessage.length > 0;
    }

    errorOk(): void {
        this.errorMessage = '';
    }

    get hasInviteCode(): boolean {
        return this.businessDetailsModel.inviteCode != null;
    }

    mounted(): void {

        this.service.get(this.$store.getters[internal.businessId]).then(x => {
            this.businessDetailsModel = x.data;
            this.resetEditData();
        })
            .catch(x => {
                this.$router.push({ name: 'error' });
            })
    }

    emailVerified(): void {
        axios.get('/api/business/EmailVerified', {
            params: {
                businessId: this.businessDetailsModel.businessId
            }
        }).then(response => {

            this.businessDetailsModel.contactEmailVerified = true;
        })
    }

    submit(data: RegisterBusinessModel): void {
        data.businessId = this.businessDetailsModel.businessId
        this.service.save(data).then(x => {
            this.businessDetailsModel.businessCity = data.city;
            this.businessDetailsModel.businessName = data.name;
            this.businessDetailsModel.businessState = data.state;
            this.businessDetailsModel.businessStreet = data.street;
            this.businessDetailsModel.businessZip = data.zip;
            this.businessDetailsModel.contactEmail = data.contactemail;
            this.businessDetailsModel.contactFirstname = data.contactfirstname;
            this.businessDetailsModel.contactLastname = data.contactlastname;
            this.businessDetailsModel.contactPhone = data.contactphone;
            this.businessDetailsModel.contactPhoneExtension = data.contactphoneextension;
            this.businessDetailsModel.fein = data.fein;

            this.businessEditModalShow = false;
        })
        .catch(error => {
            if (error.response.status == 422)
                this.errorList = error.response.data;
            else {
                this.$router.push({
                    name: 'error'
                });
            }
        })
    }

    cancelEdit(): void {
        this.resetEditData();
        this.rerenderKey += 1;
        this.businessEditModalShow = false;
    }

    resetEditData(): void {
        this.registerBusiness.city = this.businessDetailsModel.businessCity;
        this.registerBusiness.contactemail = this.businessDetailsModel.contactEmail;
        this.registerBusiness.contactfirstname = this.businessDetailsModel.contactFirstname;
        this.registerBusiness.contactlastname = this.businessDetailsModel.contactLastname;
        this.registerBusiness.name = this.businessDetailsModel.businessName;
        this.registerBusiness.state = this.businessDetailsModel.businessState;
        this.registerBusiness.street = this.businessDetailsModel.businessStreet;
        this.registerBusiness.zip = this.businessDetailsModel.businessZip;
        this.registerBusiness.contactphone = this.businessDetailsModel.contactPhone;
        this.registerBusiness.contactphoneextension = this.businessDetailsModel.contactPhoneExtension;
        this.registerBusiness.fein = this.businessDetailsModel.fein;
    }
}
