import { render, staticRenderFns } from "./backbutton.vue?vue&type=template&id=e2486422&scoped=true&"
import script from "./backbutton.ts?vue&type=script&lang=js&"
export * from "./backbutton.ts?vue&type=script&lang=js&"
import style0 from "./backbutton.css?vue&type=style&index=0&id=e2486422&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e2486422",
  null
  
)

export default component.exports