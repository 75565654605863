import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { WageItemModel } from '../../../model/wage.item.model';
import ErrorModal from '../../../components/errors/modal/errormodal.vue';

@Component({
    props: {
        wageData: Array,
        preSelectedWages: Array
    },
    components: { ErrorModal }
})
export default class WageSelect extends Vue {

    public wageDataSelected: WageItemModel[] = [] as WageItemModel[];
    public hasChanged: boolean = false;
    public columns: any[] = [
        { key: 'firstName' },
        { key: 'lastName' },
        { key: 'ssn', label: "Last Four of SSN", thStyle: { width: '10%' }  },
        { key: 'qtr' },
        { key: 'year' },
        { key: 'hours' },
        { key: 'wage' },
        { key: 'employerAddress', thStyle: { width: '15%' }  },
        { key: 'employerCity' },
        { key: 'employerPostal' },
        { key: 'employerState' },
        { key: 'company' },
    ];

    // Error Modal
    public errorMessage: string = "";

    get showErrorModal(): boolean {
        return this.errorMessage.length > 0;
    }

    get wageDataProp(): WageItemModel[] {
        return this.$props.wageData;
    }
    
    mounted(): void {

        var table = this.$refs.wageTable as any;
        this.wageDataProp.forEach((item, index) => {

            var matchRecord = this.$props.preSelectedWages.find((x: WageItemModel) =>
                x.ssn == item.ssn &&
                x.qtr == item.qtr &&
                x.year == item.year &&
                x.company == item.company &&
                x.employerAddress == item.employerAddress &&
                x.employerCity == item.employerCity &&
                x.employerState == item.employerState &&
                x.employerPostal == item.employerPostal &&
                x.employerPhone == item.employerPhone &&
                x.hours == item.hours &&
                x.wage == item.wage &&
                x.wrdId == item.wrdId);

            if (matchRecord) 
                table.selectRow(index); //preselect the rows
        });
        this.hasChanged = false;
    }
    
    public ok(): void {
        if (this.hasChanged) {
            if (this.wageDataSelected.length > 0 && this.hasInvalidQuarterYearSelection()) {
                this.errorMessage = 'You may only select records that have the same quarter and year';
            }
            else if (this.wageDataSelected.length == 0) {
                this.errorMessage = 'You must select one record';
            }
            else {
                this.$emit('save', this.wageDataSelected);
            }
        }
        else {
            this.cancel();
        }
    }

    public cancel(): void {
        this.$emit('cancel');
    }

    public onRowClicked(): void {
        this.hasChanged = true;
    }

    public onRowSelected(items: WageItemModel[]): void {
        this.wageDataSelected = items;
    }

    public hasInvalidQuarterYearSelection(): boolean {
        var result: boolean = false;
        var initialQtr: number = this.wageDataSelected[0].qtr;
        var initialYear: number = this.wageDataSelected[0].year;

        this.wageDataSelected.forEach((item) => {
            if (initialQtr != item.qtr || initialYear != item.year) {
                result = true;
            }
        });

        return result;
    }

    errorOk(): void {
        this.errorMessage = "";
    }
  
}
