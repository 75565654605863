import Vue from 'vue';
import { Component, Inject } from 'vue-property-decorator';
import { PermissionsEnum } from '../../constants/permissions';

@Component
export default class AccountMenu extends Vue {

    get isPathAccount() {
        return this.$router.currentRoute.path == this.$router.resolve({ name: 'businessdetails' }).href;  
    }

    get isPathUsers() {
        return this.$router.currentRoute.path == this.$router.resolve({ name: 'userlist' }).href;
    }

    get permissions(): any {
        return this.$store.getters['user/permissions'];
    }

    get isStaff(): boolean {
        return this.permissions.indexOf(PermissionsEnum.StaffAdmin) > -1;
    }

    get isAccountAdmin(): boolean {
        return this.permissions.indexOf(PermissionsEnum.AccountAdmin) > -1;
    }

   
}
