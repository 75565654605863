import { AxiosPromise } from 'axios';
import Base from '../components/shared/base.service';

export default class NGTSWageService extends Base {
    public constructor() {
        super();
    }

  
    public getWageData(requestId: number): AxiosPromise {
        return this.axiosHttp.post('/api/wage/getwages', requestId);
    }

    public fillName(ssn: string): AxiosPromise {
        return this.axiosHttp.post('/api/wage/getname',ssn);
    }

}