import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { UserModel } from '../../../model/user.model'
import UserProfile from '../../../components/page/userprofile/userprofile.vue';

@Component({
    props: {
        userData: Object,
        isSaving: Boolean,
        lastAdmin: Boolean
    },
    components: {
        UserProfile
    }
})
export default class UserEdit extends Vue {

    get onlyAdmin() {
        return this.$props.lastAdmin;
    }

    get isSavingProp() {
        return this.$props.isSaving;
    }

    get editErrorList() {
        return this.$props.errorList;
    }
    
    get user(): UserModel {
        return this.$props.userData;
    }

    public edit(user: UserModel): void {
        this.$emit('userEditSubmit', user);
    }

    public cancel(): void {
        this.$emit('userEditCancel');
    }
    
}
