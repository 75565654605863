import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import axios from 'axios';
import { SearchResults, SearchCriteria } from '../../../../model/search.model';
import { numberValidator } from '../../../../validation/numberValidator';
import { mask } from 'vue-the-mask';
import ErrorModal from '../../../../components/errors/modal/errormodal.vue';
import { PaginationModel } from '../../../../model/pagination.model';
import Pagination from '../../../../components/pagination/pagination.vue';
import phoneFormatter from '../../../../formatter/phoneFormatter';
import feinFormatter from '../../../../formatter/feinFormatter';

@Component({

    directives: { mask },
    components: {
        ErrorModal,
        Pagination
    }

})
export default class SearchBusiness extends Vue {
    public searchCriteria: SearchCriteria = {
        businessName: '',
        fein: ''
    } as SearchCriteria;
    public isNumeric = numberValidator.IsNumericKeyPress;
    public isBusy: boolean = false;
    public currentPage = 1;
    public perPage = 10;
    public pageOptions = [10, 20, 50];

    // Error Modal
    public errorMessage: string = "";

    public searchResultsFields: any = [
        {
            key: 'businessName',
            label: 'Name',
            sortable: true
        },
        {
            key: 'street',
            label: 'Street',
            sortable: true
        },
        {
            key: 'city',
            sortable: true,
        },
        {
            key: 'state',
            sortable: true
        },
        {
            key: 'zip',
            label: 'ZIP',
            sortable: true
        },
        {
            key: 'contactName',
            sortable: true,
        },
        {
            key: 'contactPhone',
            formatter: phoneFormatter,
            sortable: true,
        },
        {
            key: 'contactEmail',
            sortable: true,
        },
        {
            key: 'fein',
            label: 'FEIN',
            formatter: feinFormatter,
            sortable: true,
        }
    ];

    public searchResults: SearchResults[] = [] as SearchResults[];
    public formSubmitted: boolean = false;

    mounted(): void {
        this.isBusy = true;
        axios.get('/api/businesssearch', {}).then(response => {
            this.searchResults = response.data as SearchResults[];
        }).catch((error: any) => {
            this.errorMessage = "Sorry a system error has occured"
        }).then(x => {
            this.isBusy = false;
        });
    }

    businessSelected(data: any): void {
        this.$store.commit('internal/setBusiness', data[0]);
        window.location.href = '/business/details';
    }

    get totalRows(): number {
        return this.getSearchResultsFiltered.length;
    }

    get getSearchResultsFiltered(): SearchResults[] {
        return this.searchResults.filter(this.filterByBusinessName)
                                 .filter(this.filterByFEIN);
    }

    get showErrorModal(): boolean {
        return this.errorMessage.length > 0;
    }

    filterByBusinessName(value: SearchResults): boolean {
        var businessName = this.searchCriteria.businessName.length >= 3 ? this.searchCriteria.businessName.toLowerCase() : null;
        return !businessName || value.businessName.toLowerCase().indexOf(businessName) > -1;
    }

    filterByFEIN(value: SearchResults): boolean {
        var fein = this.searchCriteria.fein.replace(/\D/g, '').length == 9 ? this.searchCriteria.fein.replace(/\D/g, '') : null;
        return !fein || fein == value.fein;
    }

    errorOk(): void {
        this.errorMessage = '';
    }

    refreshPagination(model: PaginationModel): void {
        this.perPage = model.perPage;
        this.currentPage = model.currentPage;
    }
}
