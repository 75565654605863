import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { RequestUploadError } from '../../../model/request.upload.error.model';
import RequestService from '../../../api-services/request.service'
import { userStoreName, newRequestStoreName } from '../../../store/names'
import { PaginationModel } from '../../../model/pagination.model';
import vueDropzone from 'vue2-dropzone';
import Pagination from '../../../components/pagination/pagination.vue';
import ErrorModal from '../../../components/errors/modal/errormodal.vue'

@Component({
    components: {
        vueDropzone,
        Pagination,
        ErrorModal
    }
})
export default class RequestNew extends Vue {
    public subpoenaErrors: RequestUploadError[] = [] as RequestUploadError[]
    private dataUploadDropzone: any;
    public errorMessage = "";

    public currentPage_upload = 1;
    public perPage = 10;
    public pageOptions = [10, 20, 50];

    private requestService: RequestService = new RequestService();

    public dropzoneOptions: any = {
        url: '/api/request/dataupload',
        thumbnailWidth: 150,
        maxFilesize: 500,
        autoProcessQueue: false,
        addRemoveLinks: true,
        acceptedFiles: '.csv',
        maxFiles: 1,
        dictDefaultMessage: "Drag and drop file or click here to attach file"

    }

    public columns: any[] = [
        { key: 'row' },
        { key: 'column' },
        { key: 'errorMessage' }
    ];

    get totalRows(): number {
        return this.subpoenaErrors.length;
    }

    get hasRowErrors(): boolean {
        return this.subpoenaErrors.length > 0;
    }

    popOut(thisUrl: string) {
        var thisUrl = "/request/datalayout";
        var popupWindow = null;
        popupWindow = window.open(thisUrl, 'popUpWindow', 'height=800,width=1700,left=50,top=50,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes');
    }

    mounted(): void {
        this.subpoenaErrors = this.$store.getters['newrequest/requestUploadErrors'];
        this.dataUploadDropzone = this.$refs.dataUploadDropzone;
    }

    fieldErrorClass(errors: any, fieldName: string): string {
        if (!errors)
            return "";

        if (errors.find((x: any) => x.field == fieldName))
            return "text-danger font-weight-bold";

        return "";
    }

    submit(): void {
        if (this.dataUploadDropzone.getQueuedFiles().length == 0) {
            this.errorMessage = "Please choose a valid CSV file to upload."
        }
        else {
            this.dataUploadDropzone.processQueue();
        }
    }

    uploadSuccess(file: any, response: any): void {
        if (this.$store.getters[userStoreName.isStaff]) {
            this.requestService.submit(response, this.$store.getters['internal/businessId']).then(response => {
                this.$router.push({ name: 'requestconfirmation', query: { requestBatchId: response.data } })
            });
        } else {

            this.$store.dispatch('newrequest/loadNewRequest', response);
            this.$router.push({ name: 'uploadsubpoena' })
        }
    }

    uploadError(file: any, message: any, xhr: any): void {
        this.subpoenaErrors = [];

        if (xhr.status == 422) {
            if (message.errorMessage) {
                this.errorMessage = message.errorMessage;
            }
            else {

                this.subpoenaErrors = message.rowErrors;
            }
        }
        else {
            this.errorMessage = "A System error has occured."
        }

        this.dataUploadDropzone.removeAllFiles();
    }

    refreshPagination(model: PaginationModel): void {
        this.perPage = model.perPage;
        this.currentPage_upload = model.currentPage;
    }

    maxFilesError(file: any): void {
        this.dataUploadDropzone.removeFile(file);
        this.errorMessage = "There is a maximum of one data file"
    }

    errorOk(): void {
        this.errorMessage = "";
    }

    get showErrorModal(): boolean {
        return this.errorMessage.length > 0;
    }
}
