import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { UserModel } from '../../../model/user.model'
import UserProfile from '../../../components/page/userprofile/userprofile.vue'

@Component({
    props: {
        userData: Object,
        isSaving: Boolean
    },
    components: {
        UserProfile
    }
})
export default class UserAdd extends Vue {

    get isSavingProp() {
        return this.$props.isSaving;
    }
    
    get user() {
        return this.$props.userData;
    }

    get addErrorList() {
        return this.$props.errorList;
    }

    public add(user: UserModel): void {
        this.$emit('userAddSubmit', user);
    }

    public cancel(): void {
        this.$emit('userAddCancel');
    }
}
