export const internal = {
    namespaced: true,
    state: {
        businessId: "",
        businessName: "",
        contactName: "",
    },
    getters: {
        businessId(state: any): number | null {
            var businessId = sessionStorage.getItem('actingbusinessId');
            if (businessId)
                return parseInt(businessId);

            return null;
        },
        businessName(state: any): string {
            return sessionStorage.getItem('actingbusinessName') as string;

        },
        contactName(state: any): string {
            return sessionStorage.getItem('actingContactName') as string;

        }
    },

    actions: {
        async clearImpersonation(context: any, businessDetails: any) {
            context.commit('clear');
        }
    },

    mutations: {
        setBusiness(state: any, business: any) {
            sessionStorage.setItem("actingbusinessId", business.businessId);
            sessionStorage.setItem("actingbusinessName", business.businessName);
            sessionStorage.setItem("actingContactName", business.contactName);
        },

        clear(state: any) {
            sessionStorage.removeItem("actingbusinessId");
            sessionStorage.removeItem("actingbusinessName");
            sessionStorage.removeItem("actingContactName");
        }
    }
}

